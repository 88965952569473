<template >
  <div v-if="filters" class="w-100 filter">
    <v-text-field
      v-model="filters.text"
      density="compact"
      variant="outlined"
      placeholder="Search/filter/sort"
      hide-details
      :prepend-inner-icon="$icons.searh"
      color="primary"
      :disabled="disabled"
      @update:model-value="menu=false"
    >  
      <template #append-inner>
        <v-tooltip location="top" text="Open Filters">
          <template v-slot:activator="{ props }">
            <v-btn
              size="small"
              variant="outlined"
              class="test"
              color="outlined"
              @click="menu=true"
              v-bind="props"
            >
              <v-icon size="large">{{ $icons.tune }}</v-icon>
              <v-badge v-if="showBadge" :content="filtersCount" color="primary" floating/>
            </v-btn>
          </template>
        </v-tooltip>
      </template>
    </v-text-field>
    <div class="f-div">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        location="bottom"
        activator="parent"
      >
        <v-card class="pa-1 c-black">
          <v-card-text class="pb-0">
            <slot></slot>
          </v-card-text>
          <div class="pb-0 d-flex">
            <v-spacer/>
            <v-btn
              :disabled="filtersCount === 0"
              variant="text"
              color="secondary"
              @click="resetFilters()"
            >Reset filters
            </v-btn>
          </div>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FilterBase',
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
    },
    storage: {
      type: String,
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      menu: false,
      filters: this.modelValue,
      filtersCopy: null as any,
      filtersCount:0,
    }
  },
  computed:{
    showBadge() {
      return this.filtersCount > 0 && !this.menu
    },
  },
  watch: { 
    filters: {
      // eslint-disable-next-line no-unused-vars
      handler(val) {
        this.$emit('update:modelValue', val)
      },
      deep: true,
    },
    modelValue: {
      handler(val) {
        this.filtersCount = 0

        for (const key of Object.keys(this.modelValue))
        {
          if (!this.$utilities.equals(this.modelValue[key], this.filtersCopy[key]))
          {
            this.filtersCount++
          }
        }
        if (this.storage)
        {
          this.$filters[this.storage] = val
        }
        this.filters = val
      },
      deep: true,
    },
  },
  created() {
    this.filtersCopy = this.$utilities.deepClone(this.filters)
    if (this.storage && this.$filters[this.storage]) {
      this.filters = this.$filters[this.storage]
    }
  },
  methods: {
    resetFilters() {
      this.$utilities.deepCloneWithRef(this.filtersCopy, this.filters)
    },
    clickOutside() {
      if (this.menu)
      {
        this.menu = false
      }
    },
  },
})
</script>

<style scoped lang="scss">
.f-div{
    height:1px;
    margin-top:-1px;
}
.test {
  // on hover change the background color
  &:hover {
    background-color: var(--color-green);
    border: 1px solid var(--color-green);
    color: var(--color-white);
  }
}
</style>
