/* eslint-disable */
interface Number {
  timestampToDate(): Date
  timestampToDateString(): string
  timestampToLongDateString(): string
  timestampToTime(format: string, short?: boolean): string
  secondsToTime(format: string, short?: boolean): string
  numberToTime(): string
  isToday(): boolean
}  
interface String {
  dateToDateString(): string
  timeToArray(): Array<number>
  timeToNumber(): number
  dayNameToNumber(): number
}  
// returns a date object from timestamp
Number.prototype.timestampToDate = function (): Date {  
  if (this === undefined) 
    return new Date()
  return new Date(this as number)
}
// returns date from timestamp (ex: 1654725599000 => 2022-06-08)
Number.prototype.timestampToDateString = function (): string {  
  if (this === undefined) return ''
  const p = (new Date(Number(this)).toLocaleString()).split('T')

  return p[0]
}
// returns date string from timestamp (ex: 1654725599000 => Wed Jun 08 2022)
Number.prototype.timestampToLongDateString = function (): string {  
  if (this === undefined) return ''
  const date = new Date(Number(this))
  
  return date.toDateString()
}
// returns true if timestamp is in a current day
Number.prototype.isToday = function (): boolean {  
  if (this === undefined) 
    return false
  
  const now = new Date()
  const date = this.timestampToDate()

  return date.getDate() === now.getDate() &&
         date.getMonth() === now.getMonth() &&
         date.getFullYear() === now.getFullYear()
}
// returns time format from timestamp (ex: 1654725599000 => 21:59:59)
Number.prototype.timestampToTime = function (format: 'hh:mm:ss' | 'hh:mm', short = false): string {  
  if (this === undefined) return ''
  let time = ''
  if(format === 'hh:mm:ss')
    time = new Date(Number(this)).toLocaleString().substring(11, 19)
  else
    time = new Date(Number(this)).toLocaleString().substring(11, 16)
  if (short) {    // removes 00 hours
    if (time.startsWith('00:')) time = time.substring(3)
  }
  return time
}
// returns time format from seconds (ex: 1120 => 00:18:40)
Number.prototype.secondsToTime = function (format: 'hh:mm:ss' | 'hh:mm', short = false): string { 
  const temp = Number(this) * 1000 
  return temp.timestampToTime(format, short)
}
// converts a number 10.07 to time '01:07'
Number.prototype.numberToTime = function (): string { 
  const time = parseFloat(this.toFixed(2))

  const hour = Math.floor(time)
  const minute = ((time - hour) * 100).toFixed(0)

  return hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0')
}

// returns date string from date (ex: 2023-03-15 => Wed Mar 15 2023)
String.prototype.dateToDateString = function (): string {  
  if (this === undefined) return ''
  const date = new Date(this as string)

  return date.toDateString()
}
// converts a time '01:07' into an array of numbers [1,7]
String.prototype.timeToArray = function (): Array<number> { 
  const arr = this.split(':')

  return arr.map((timePart) => {
    return parseInt(timePart)
  })
}
// converts a time '01:07' to number 10.07
String.prototype.timeToNumber = function (): number { 
  const arr = this.split(':')
  const dec = parseInt(arr[1])

  return parseFloat(parseInt(arr[0]) + '.' + (dec < 10 ? '0' : '') + dec)
}
// converts a day name to number on a week (sunday => 0)
String.prototype.dayNameToNumber = function (): number { 
  switch (this) {
    case 'sunday':
      return 0
    case 'monday':
      return 1
    case 'tuesday':
      return 2
    case 'wednesday':
      return 3
    case 'thursday':
      return 4
    case 'friday':
      return 5
    case 'saturday':
      return 6
  }
  return 0
}
