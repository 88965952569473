<template>
  <div
    data-cy="file-upload"
    class="dragContainer"
    :class="{'video': canUploadByUrl}"
    @dragover.prevent="onDragOver()" 
    @dragleave.prevent="onDragLeave()"
    @drop.prevent="onDrop($event)"
  >
    <input
      v-if="allowInput"
      ref="fileControl"
      type="file"
      :accept="accept"
      class="input-file"
      :multiple="multiple"
      @change="selectedFiles($event.target)"
    />
    <b>{{ statusTitle }}</b>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { fileHelper } from '@/helpers/file'
import { useMessageBoxStore, MessageConfig } from '@/store/message-box'

export interface FileData{
  file: any,
  base64:string,
  extension: string,
  imageSize?:{
    width: number,
    height: number
  }
}

export default defineComponent({
  name: 'FileUploaderV2',
  components: {
  },

  props: {
    title: {
      type: String,
      required: false,
      default() {
        return 'Drag & Drop here your file'
      },
    },
    mode: {
      type: String,
      required: false,
      default() {
        return 'all'              // onlyDragAndDrop|onlyInput|all
      },
    },
    dragTitle: {
      type: String,
      required: false,
      default() {
        return 'Leave here your file'
      },
    },
    uploadTitle: {
      type: String,
      required: false,
      default() {
        return 'Uploading...'
      },
    },
    fileType: {
      type: String,
      default() {
        return 'image'
      },
    },
    canUploadByUrl: {
      type: Boolean,
    },
    maxFileSize: {
      type: Number,
      default: 512000000,
    },
    multiple: {
      type: Boolean,
    },
    maxFiles: {
      type: Number,
      default: -1
    },
    maxFilesMessage: {
      type: String,
    },
  },

  emits: ['onResize', 'onUploaded', 'onUploadedMultiple', 'onUploading'],
  setup() {
    // refs
    const fileControl: any = ref('fileControl')
    const messageBox = useMessageBoxStore()

    window.stores = { messageBox }
    return {
      fileControl,
      messageBox
    }
  },

  // #region Properties
  data() {
    return {
      drag_status: 0,
      accept: '',
      filesData: [] as FileData[]
    }
  },
  mounted() {
    this.accept = fileHelper.getSupportedExtensions(this.fileType as 'document' | 'image' | 'video')
  },
  computed: {
    statusTitle() {
      const titles = [this.title, this.dragTitle, this.uploadTitle]

      return titles[this.drag_status]
    },
    allowInput() {
      // returns true if mode allow input file
      return (this.mode.trim().toLowerCase() === '' || this.mode.trim().toLowerCase() === 'all' || this.mode.trim().toLowerCase() === 'onlyinput')
    },
    allowDragAndDrop() {
      // returns true if mode allow input file
      return (this.mode.trim().toLowerCase() === '' || this.mode.trim().toLowerCase() === 'all' || this.mode.trim().toLowerCase() === 'onlydraganddrop')
    },
  },
  // #endregion Life Cycle

  // #region Watchers
  watch: {
  },
  // #endregion Properties

  methods: {
    // #region Drag & Drop Event Listners
    // eslint-disable-next-line no-unused-vars
    onDragOver() {
      if (!this.allowDragAndDrop) 
        return
      this.drag_status = 1
    },
    // eslint-disable-next-line no-unused-vars
    onDragLeave() {
      if (!this.allowDragAndDrop) 
        return
      this.drag_status = 0
    },
    onDrop(event: any) {
      if (!this.allowDragAndDrop) return
      this.drag_status = 2
      this.checkFiles(event.dataTransfer.files)    // gets only the 1st dropped file
    },
    // #endregion Drag & Drop Event Listners
    selectedFiles(target: any)
    {
      this.checkFiles(target.files)
    },
    async checkFiles(files: any []){
      //checking rules
      if (this.maxFiles > 0 && files.length > this.maxFiles)
      {
        let con = true
        await this.maxFileNumberMessage().then((value) => {
          if (!value)
          {
            this.drag_status = 0
            con = false
          }
        })
        if (!con)//this need because you can not exit on above function
        {
          return
        }
      }
      
      for (const file of files) {
        const extension = file.name? file.name.substring(file.name.lastIndexOf('.')) : 'noext'

        if (this.accept.indexOf(extension) < 0) {
          // Wrong file type
          this.drag_status = 0
          this.fileFormatMessage(file.type)
          return
        } 
        else if (file.size > this.maxFileSize)
        {
          this.drag_status = 0
          this.maxFileSizeMessage()
          return
        }
      }
      this.convertFiles(files)
    },
    // #region Utils
    convertToFiledata(file): Promise<FileData> {
      const self = this 
      return new Promise(function(resolve, reject) {
        const reader = new FileReader()
        
        reader.onload = async (f: any): Promise<void> => {
          let fileData = {
            base64: f.target.result,
            extension: file.name.substring(file.name.lastIndexOf('.')),
            file: file
          } as FileData
          // just resolve with the data you want
          if (self.fileType === 'image')
          {
            fileData = await self.convertImageToFiledata(f, fileData)
          }
          resolve(fileData);
        };
        // and reject any errors
        reader.onerror = reject;
        reader.readAsDataURL(file);
      })
    },
    convertImageToFiledata(f: any, fileData: FileData): Promise<FileData> {
      const self = this 
      return new Promise(function(resolve, reject) {
        const img = new Image()             // loads the image to get (and integrate) its size

        img.addEventListener('load', async function () {
          fileData.imageSize =
          {
            width: this.width,
            height: this.height
          }
          fileData = await self.raise_Multiple(fileData)
          resolve(fileData);
        })
        img.src = f.target.result.toString()

        img.onerror = reject;
      })
    },
    convertFile(file: any) {
      
      this.$emit('onUploading') 
      const self = this 
      //convert to base64
      const reader = new FileReader()
      reader.onload = (f: any): void => {
        const fileData = {
          base64: f.target.result,
          extension: file.name.substring(file.name.lastIndexOf('.')),
          file: file
        } as FileData
        if (this.fileType === 'image')
        {
          const img = new Image()             // loads the image to get (and integrate) its size

          img.addEventListener('load', function () {
            fileData.imageSize =
            {
              width: this.width,
              height: this.height
            }
            self.raise_onUpload(fileData)         // ATTENTIONS: here we were using self instead of this. sends file meta-data and its data
            self.drag_status = 0              // ATTENTIONS: here we were using self instead of this. 
          })
          img.src = f.target.result.toString()
        }
        else
        {
          self.$emit('onUploaded', fileData)
          self.drag_status = 0              // ATTENTIONS: here we were using self instead of this. 
        }
      }
      reader.readAsDataURL(file);
    },
    async convertFiles(files: any []) {
      this.$emit('onUploading') 
      const filesData = [] as FileData[]
      //convert to base64
      for (const file of files) {
        const fileData = await this.convertToFiledata(file)
        filesData.push(fileData)
      }
      this.$emit('onUploadedMultiple', filesData)
      this.fileControl.value = null  
    },
    // #endregion Utils

    // #region Events
    async raise_onUpload(fileData: FileData) {
      // checks if we have to resize to maxSize
      const cropperMaxSiz = 340

      if (fileData.imageSize && (fileData.imageSize.width > cropperMaxSiz || fileData.imageSize.height > cropperMaxSiz)) {
        fileData.base64.resizeBase64(cropperMaxSiz).then((result: any) => {
          fileData.base64 = result.data
          fileData.imageSize = {
            width: result.width,
            height: result.height
          }
          this.$emit('onResize', fileData)        // notify auto-resize
          this.$emit('onUploaded', fileData)
        })
      } else {
        this.$emit('onUploaded', fileData)
      }
    },
    async raise_Multiple(fileData: FileData): Promise<FileData> {
      // checks if we have to resize to maxSize
      const cropperMaxSiz = 340

      if (fileData.imageSize && (fileData.imageSize.width > cropperMaxSiz || fileData.imageSize.height > cropperMaxSiz)) {
        await fileData.base64.resizeBase64(cropperMaxSiz).then((result: any) => {
          fileData.base64 = result.data
          fileData.imageSize = {
            width: result.width,
            height: result.height
          }
          this.$emit('onResize', fileData)        // notify auto-resize 
        })
      } 
      return fileData
    },
    maxFileSizeMessage()
    {
      const msg: MessageConfig = {
          title: 'Warning',
          message: 'You have uploaded a file larger than 512 MB size limit. Please try again with a smaller file.',
          icon: 'mdi mdi-alert-box',
          buttons: [
            {
              key: 'ok',
              text: 'Close',
              button: true,
            },
          ],
        }

        this.messageBox.show(msg)
    },
    fileFormatMessage(fileType: string)
    {
      const msg: MessageConfig = {
          title: 'Warning',
          message: `Wrong file format. Please, select an ${fileType} file!`,
          icon: 'mdi mdi-alert-box',
          buttons: [
            {
              key: 'ok',
              text: 'Close',
              button: true,
            },
          ],
        }

        this.messageBox.show(msg)
    },
    maxFileNumberMessage(): Promise<boolean>
    {
      const msg: MessageConfig = {
        title: 'Disconnect Calendar',
        message: this.maxFilesMessage? this.maxFilesMessage : `You are trying to upload more than ${this.maxFiles} files. Are you sure you want to proceed?`,
        icon: 'mdi-link-off',
        buttons: [
          {
            key: 'cancel',
            text: 'No ',
            button: false,
          },
          {
            key: 'ok',
            text: 'Yes',
            button: true,
          },
        ],
      }

      const ret = new Promise<boolean>((resolve) => {
        this.messageBox.show(msg, (result: string) => {
          // MessageBox callback
          if (result === 'ok') {
            resolve(true)
          }
          else
          {
            resolve(false)
          }
        })
      });
      return ret
    },
    // #region Events
  },
})
</script>

<style scoped lang="scss">
.dragContainer {
  width:            100%;
  display:          flex;
  justify-content:  center;
  align-items:      center;
  text-align:       center;
  border-style:     dotted;
  border-width:     1px;
  padding:          30px;
  opacity:          .5;
  background:       transparent url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH5gULCyk0eo/e8QAABO9JREFUeNrtmu1v1EUQxz+9FuwDlFLbuqY8SEUSbOOgEh9i4A1gYvx7jRIhaoxvEDUTSiigpTwI3aQpLVIofbjzRfdCue7e79f+5nqn3PdVu3c3s/Pd2Z3ZmYU22mijjTZeX3Q0SrCI7AXeBroLiloGHqnqyn+GABH5CBg1Fvu3qv7e8gSIyDmgt0GO9UxVL1sK7DQ2/kPgzQZu2T3OuT7v/ayVwJKh8V3AoV04tw4FXSboMpzYcGL8T6BcYIGOR8ZHgIetRsCByNhdVb1R0LP2AEdrhvutCCgZEtAfGXtiIPdJTrKbTkBsUosGchdzkm2/BURkHzAYkpl6IXM9kfA0ioBuETmeEcUqIYmaV9Wn28oDRGQMOAHsKTDxsqp+YxRhvi7oravALVWdrusBItINnDFIXwEeGG6vB8CRIvkDMC4i7wI/q+ryljNARPqAC0bGV4BJQwImg8yi6AYuBFtfZoIiUgrGW6TGy8CPqrpqZb33vuKcuxfuFxah+6hz7i/vfaUq7HSdPbYAzId9VI+gMvBYVee2sbdLAKqamSgFt/1eRIaAgxlnQiW4/SAwkIh+p4ErHSLSC5yLfGkF+KXeCbqDw+ww4MLE9kb0zQOzqnrfUOc+4IuIPoDLHSIyDoxFGPxWVdcNJlACJiLZXBbuApN5vCPHHDqBryIePN3pnHsfeKPmg+uqOm+04mcTbpiFAeCEc+659/5JXrKdcx84544455a89y82nSFr4Q7xym24BPTFig9GRZFTBl58KsjKg89DuHTA2bDy9cJyXymWTRUtP4nIp8YVodEgMwuDNf8fzLCp0/I2WDV+IuJqm3EPuF+7xURkEDhcJ+EZEZEJVa2XXzxiow5ZNTozInUZGz8EHEt8PAv8ljrUAiHzInIN+Di4cS2OichsyjBVvSoix8KZNr3b9YBqPhHD9VgenjCiDPwa7iPjCR3f1fn9nWaVxI4mLk838xpfY8g0cDOW1wddLVcPeC92lVXVWzsVGH67mFNX8wgQkR6gJ/LRHwbiYzJ6gs6W8YC3ImNPVfWfooKDjKc5dTaNgIFESLJCTNaBViKgN3GLtEJMVl8rEVBKlKGssNqouVsRUGlwhCnl1Nk0Al5ExvYbErA/p86mERC7rg4bEjCcU2fTCJhLXF4Kyw8yRnLqbA4BdYonJw3En9ymzqalwjORsTER6S+w+v1sLdcB3LGatCUBqS7wmdBw2a7x1SZNDFMtR4CqrgG3EzrOi8jwNowfBs4n5nc76DKBaT1AVadEZDSSGXYAn4nIHHBDVRcShg+EPT+UUPFMVacs52xeEgN+Ar4k3rkdCluizEYP4NmmVHowwyPXg2xTlKwFBve8BKxl6B1io/53JPxdby5rwCVL128YAYGEFeCi0YVoAbjYqIeSnc6547VEOOdmvPeFukLe+4r3/p5z7nlIZLbbeC1vcKnXvPeF8/7wcrX2wdV6F7DE1icno1axNvT57ovIIeAdNtXqE3gMzKjqA+PFjj3hW2p4bzCxGgeAfbxsWK6wUUFabJC+ZG9wV7vDzUBmdzh86RPSNba87wNaCVnvAwC8ql6p5gFXg4vEosIAO+vutjLKweaNZCW0jx+Sbmv93/BDNax2bgpbq8bvcFoRy8H455tz9NjBYfFOsJWQfCfYkeMEzfNStFUPwsyXom200UYbbbzO+BdIObQJUJgEaQAAAABJRU5ErkJggg==') center center no-repeat;
  &.video
  {
    height: calc(100% - 100px);
  }
  &:not(.video)
  {
    height: 100%;
  }
}
.input-file {
  background-color:red;
  opacity:          0;
  width:            50%;
  max-width:        50%;
  height:           50%;
  min-height:       50%;
  position:         absolute;
  cursor:           pointer;
}
</style>