import { reactive } from 'vue'
export default class Filters {

  public get studies(): any {
    if (sessionStorage.getItem('filter_studies')) {
      return JSON.parse(sessionStorage.getItem('filter_studies') as string)
    }

    return null
  }

  public set studies(value: any) {
    if (value === null) {
      sessionStorage.removeItem('filter_studies')

      return
    }
    sessionStorage.setItem('filter_studies', JSON.stringify(value))
  }

  public get sessionParticipant(): any {
    if (sessionStorage.getItem('filter_sessionParticipant')) {
      return JSON.parse(sessionStorage.getItem('filter_sessionParticipant') as string)
    }

    return null
  }

  public set sessionParticipant(value: any) {
    if (value === null) {
      sessionStorage.removeItem('filter_sessionParticipant')

      return
    }
    sessionStorage.setItem('filter_sessionParticipant', JSON.stringify(value))
  }

  public get users(): any {
    if (sessionStorage.getItem('filter_users')) {
      return JSON.parse(sessionStorage.getItem('filter_users') as string)
    }

    return null
  }

  public set users(value: any) {
    if (value === null) {
      sessionStorage.removeItem('filter_users')

      return
    }
    sessionStorage.setItem('filter_users', JSON.stringify(value))
  }

  public resetAll(): void {
    this.studies = null
    this.sessionParticipant = null
    this.users = null
  }
  
}

const filters: Filters = reactive(new Filters())

export { filters } 