import { ConduxService } from '@/services/condux.service'
import {
  ConduxApiCommonV1Response,
  ConduxApiResearcherV1ProjectGetResponse,
  ConduxApiResearcherV1ProjectPostResponse,
  ConduxApiResearcherV1ProjectPutResponse,
  ConduxApiResearcherV1ProjectsGetRequest,
  ConduxApiResearcherV1ProjectsGetResponse,
  Project,
  ProjectHelper,
} from '@conduxio/types'

export class ProjectService extends ConduxService {

  protected modelNameSingular = 'project'
  protected modelNamePlural = 'projects'

  public read(id: string, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1ProjectGetResponse> {
    const apiRequest = {url: this.modelNameSingular + '/' + id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId }

    return super.get<ConduxApiResearcherV1ProjectGetResponse>(apiRequest)
  }

  public readAll(request: ConduxApiResearcherV1ProjectsGetRequest, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1ProjectsGetResponse> {
    const apiRequest = {url: this.modelNameSingular, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, request: request }

    return super.get<ConduxApiResearcherV1ProjectsGetResponse>(apiRequest)
  }
  
  public create(model: Project, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1ProjectPostResponse> {
    model.workspaceId = this.globalsService.getWorkspaceId()
    const validationResult = ProjectHelper.validate(model)

    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1ProjectPostResponse>((resolve) => {
        resolve({
          status: 'ERROR',
          errorMessage: 'Error creating Project, ' + validationResult.invalidReason,
        } as ConduxApiResearcherV1ProjectPostResponse)
      })
    }

    const apiRequest = {url: this.modelNameSingular, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, object: { project: validationResult.project } }

    return super.post<ConduxApiResearcherV1ProjectPostResponse>(apiRequest)
  }

  public update(model: Project, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1ProjectPutResponse> {
    model.workspaceId = this.globalsService.getWorkspaceId()
    const validationResult = ProjectHelper.validate(model)

    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1ProjectPutResponse>((resolve) => {
        resolve({
          status: 'ERROR',
          errorMessage: 'Error updating Project, ' + validationResult.invalidReason,
        } as ConduxApiResearcherV1ProjectPutResponse)
      })
    }

    const apiRequest = {url: this.modelNameSingular + '/' + model.id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, object: { project: validationResult.project }, modelId: model.id }

    return super.put<ConduxApiResearcherV1ProjectPutResponse>(apiRequest)
  }

  public remove(id: string, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiCommonV1Response> {
    const apiRequest = {url: this.modelNameSingular + '/' + id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, modelId: id }

    return super.delete<ConduxApiCommonV1Response>(apiRequest)
  }
}
