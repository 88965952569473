<template>
  <div v-if="$globalsService.userWorkspace" class="d-inline w-100">
    <v-menu location="bottom" :disabled="$globalsService.userWorkspaces.length === 1">
      <template #activator="{ props }">
        <div v-bind="props" class="w-100 select b-white pa-2" :class="{'cursor-pointer': $globalsService.userWorkspaces.length > 1}">
          <img v-if="$globalsService.userWorkspace.logo" class="h-100" :src="$globalsService.userWorkspace.logo"/>
          <v-icon v-if="$globalsService.userWorkspaces.length > 1" class="float-right c-green">{{ $icons.menu_swap }}</v-icon>
        </div>
      </template>
      <v-card min-width="150" class="mt-1 list">
        <template v-for="item in $globalsService.userWorkspaces" :key="item.id">
          <div 
            v-if="item.id  !== $globalsService.getWorkspaceId()"
            class="pa-2 item cursor-pointer b-white" 
            @click="change_workspace(item)"
          >
            <img class="h-100" :src="item.logo"/>
          </div>
        </template> 
      </v-card>           
    </v-menu>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  Role,
} from '@conduxio/types'
export default defineComponent({
  name: 'WorkspaceSelect',
  data() {
    return {

    }
  },
  computed:{
  },
  methods: {
    change_workspace(workspace)
    {
      //change workspace
      this.$globalsService.userWorkspace = workspace
      //change role
      this.$globalsService.userRole = this.$globalsService.workspaceRoles.find((r) => r.workspaceId === workspace.id) as Role

      const utw = this.$globalsService.userToWorkspaceId.find((sw) => sw.userId === this.$globalsService.loggedInUser.id)
      if (utw)
        utw.workspaceId = workspace.id
      
      setTimeout(() => {
        if ((this.$route.name as string).toLowerCase() === 'dashboard') {
          this.$router.go(0)             // refresh the current page
        } else {
          this.$router.push('/dashboard') // redirects on studies
        }
      }, 500)
    },
  },
})
</script>
<style scoped lang="scss">
@use '@/assets/scss/colors.scss' as c;
.select
{
  border-radius: 5px;
  height: 50px;
  i
  {
    margin-top:5px;
  }
}
.item
{
  height: 50px;
  &:hover
  {
    background-color: var(--color-medium_gray) !important;
  }
}
</style>