import { ConduxService } from '@/services/condux.service'
import {
  ConduxApiCommonV1Response,
  ConduxApiResearcherV1FilesGetRequest,
  ConduxApiResearcherV1FileGetResponse,
  ConduxApiResearcherV1FilesGetResponse,
  ConduxApiResearcherV1FilePostBody,
  ConduxApiResearcherV1FilePostResponse,
  ConduxApiResearcherV1FilePutBody,
  ConduxApiResearcherV1FilePutResponse,
  ConduxApiResearcherV1FileUploadUrlGetRequest,
  ConduxApiCommonV1UrlResponse
} from '@conduxio/types'

export class FileService extends ConduxService {

  protected modelNameSingular = 'file'
  protected modelNamePlural = 'files'

  public read(request: ConduxApiResearcherV1FilesGetRequest, withoutWorkspaceId = false, workspaceId = '', include = ''): Promise<ConduxApiResearcherV1FileGetResponse> {
    const apiRequest = {url: this.modelNameSingular + '/' + request.id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, include:include }

    return super.get<ConduxApiResearcherV1FileGetResponse>(apiRequest)
  }

  public readAll(request: ConduxApiResearcherV1FilesGetRequest, withoutWorkspaceId = false, workspaceId = '', include = ''): Promise<ConduxApiResearcherV1FilesGetResponse> {
    const apiRequest = {url: this.modelNameSingular, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, include:include, request: request }
    
    return super.get<ConduxApiResearcherV1FilesGetResponse>(apiRequest)
  }
  
  public create(request: ConduxApiResearcherV1FilePostBody, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1FilePostResponse> {
    const apiRequest = {url: this.modelNameSingular, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, object: request }

    return super.post<ConduxApiResearcherV1FilePostResponse>(apiRequest)
  }

  public update(id: string, request: ConduxApiResearcherV1FilePutBody, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1FilePutResponse> {
    const apiRequest = {url: this.modelNameSingular + '/' + id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, object: request, modelId: id }

    return super.put<ConduxApiResearcherV1FilePutResponse>(apiRequest)
  }

  public remove(id: string, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiCommonV1Response> {
    const apiRequest = {url: this.modelNameSingular + '/' + id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, modelId: id }

    return super.delete<ConduxApiCommonV1Response>(apiRequest)
  }

  public getUploadUrl(request: ConduxApiResearcherV1FileUploadUrlGetRequest, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiCommonV1UrlResponse> {
    const apiRequest = {url: this.modelNameSingular + '/upload-url', withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, request: request }

    return super.get<ConduxApiCommonV1UrlResponse>(apiRequest)
  }

  public uploadFile(file: any, url: string): Promise<ConduxApiCommonV1Response> {
    return super.UploadBigFile(file, url)
  }
}
