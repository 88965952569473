import { createApp } from 'vue'
import App from './App.vue'

import { RelativeTimeHelper } from '@/helpers/relative-time.helper'
import { DateTimeHelper } from '@conduxio/types'

// #region SCSS
import '@/assets/scss/global.scss'
import '@/assets/scss/colors.scss'
import '@/assets/scss/colors-sessions.scss'
import '@/assets/scss/text.scss'
import '@/assets/scss/tables.scss'
// #endregion SCSS

// #region Meta
import { createMetaManager } from 'vue-meta'
// #endregion Meta

// #region v-calendar
import VCalendar from 'v-calendar'
import 'v-calendar/dist/style.css'
// #endregion v-calendar

// #region Icons
import icons from '@/static/icons'
// #endregion Icons

// appdata: name-value pairs to populate forms
import appData from '@/static/appdata'

import Notifications from '@kyvg/vue3-notification'

// Plugins
import { registerPlugins } from '@/plugins'

// #region services and globalFunctions
import { GlobalsService, globalInstance } from './services/globals.service'
import { Utilities } from './helpers/utilities'
import { Notification } from './helpers/notification'
import validationRules from '@/helpers/validation-rules'
import registerComponents from './plugins/baseComponent'
import { filters } from '@/store/filters'

import 'core-js/actual/array/group-by'
import  './helpers/time'
import './helpers/images'
import './helpers/general'
import './helpers/colors'
import './helpers/question'

// Create App
const app = createApp(App)

app.config.globalProperties.$globalsService = globalInstance as GlobalsService
app.config.globalProperties.$icons = icons
app.config.globalProperties.$appData = appData
app.config.globalProperties.$utilities = new Utilities()
app.config.globalProperties.$rules = validationRules
app.config.globalProperties.$filters = filters
app.config.globalProperties.$notification = new Notification()
app.config.globalProperties.$pushNotifications = []

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $globalsService: GlobalsService;
    $icons: any;
    $utilities: Utilities;
    $rules: any;
    $appData: any;
    $filters: any;
    $notification: any;
    $pushNotifications: Array<any>;
  }
}

registerPlugins(app)
registerComponents(app)

app.use(createMetaManager())
  .use(VCalendar)
  .use(Notifications)
  .directive('relative-time', {
    mounted(el: HTMLElement, binding) {
      if (el && binding && binding.value && ['string', 'number'].includes(typeof binding.value)) {
        el.innerText = RelativeTimeHelper.getRelativeTime(binding.value, binding.arg)
      }
    },
  })
  .directive('date-time', {
    mounted(el: HTMLElement, binding) {
      if (el && binding && binding.value && ['string', 'number'].includes(typeof binding.value)) {
        el.innerText = DateTimeHelper.fromTimestamp(binding.value, binding.arg as string) as string
      }
    },
  })
app.mount('#app')
