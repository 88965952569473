import { Researcher, Role, Workspace, RoleHelper } from '@conduxio/types'
//import { TokenHelper } from '../helpers/token.helper'
import { reactive } from 'vue'
import { useLocalStorage, useSessionStorage, RemovableRef } from '@vueuse/core'

type LastUrl = { userId: string, workspaceId: string, url: string }
type LoggedUser = { researcher: Researcher, token: string, refreshToken: string }
type UserToWorkspaceId = { userId: string, workspaceId: string }

export class GlobalsService {
  public showLoading = false as boolean
  public apiRequestInProgressCount = 0 as number
  //Saved in local storage
  private _lastUrls = useLocalStorage('last_url', [] as LastUrl[]) as RemovableRef<LastUrl[]>//contains the mapping from userId to lasturl used
  public get lastUrls(): LastUrl[] {
    return this._lastUrls as unknown as LastUrl[] 
  }
  public set lastUrls(value: LastUrl[] ) {
    this._lastUrls = value as unknown as RemovableRef<LastUrl[]>
  }
  private _loggedUsers = useLocalStorage('logged_users', [] as LoggedUser[]) as RemovableRef<LoggedUser[]>//Contains the list of workspaces to which a user belongs
  public get loggedUsers(): LoggedUser[] {
    return this._loggedUsers as unknown as LoggedUser[] 
  }
  public set loggedUsers(value: LoggedUser[] ) {
    this._loggedUsers = value as unknown as RemovableRef<LoggedUser[]>
  }
  private _userToWorkspaceId = useLocalStorage('userToWorkspaceId', [] as UserToWorkspaceId[]) as RemovableRef<UserToWorkspaceId[]>//Contains the list of workspaces to which a user belongs
  public get userToWorkspaceId(): UserToWorkspaceId[] {//workspace attualmente in uso
    return this._userToWorkspaceId as unknown as UserToWorkspaceId[] 
  }
  public set userToWorkspaceId(value: UserToWorkspaceId[] ) {
    this._userToWorkspaceId = value as unknown as RemovableRef<UserToWorkspaceId[]>
  }
  private _theme = useLocalStorage('theme', 'light')//Contains the list of workspaces to which a user belongs
  public get theme(): 'dark' | 'light' {
    return this._theme as unknown as 'dark' | 'light'
  }
  public set theme(value: 'dark' | 'light' ) {
    this._theme = value as unknown as RemovableRef<'dark' | 'light'>
  }
  private _pushNotificationToken = useLocalStorage('pushNotificationToken', null) as unknown as RemovableRef<string>//need for firebase
  public get pushNotificationToken(): string {
    return this._pushNotificationToken as unknown as string
  }
  public set pushNotificationToken(value: string) {
    this._pushNotificationToken = value as unknown as RemovableRef<string>
  }
  private _showWelcome = useLocalStorage('showWelcome', true)//first time for ask to accept push notifications
  public get showWelcome(): boolean {
    return this._showWelcome as unknown as boolean
  }
  public set showWelcome(value: boolean) {
    this._showWelcome = value as unknown as RemovableRef<boolean>
  }
  //Saved in session storage
  private _loggedInUser = useSessionStorage('logged_in_user', {} as Researcher)//current logged user
  public get loggedInUser(): Researcher {
    return this._loggedInUser as unknown as Researcher
  }
  public set loggedInUser(value: Researcher | null ) {
    this._loggedInUser = value as unknown as RemovableRef<Researcher>
  }
  private _userIdToken = useSessionStorage('user_id_token', null) as unknown as RemovableRef<string>
  public get userIdToken(): string {
    return this._userIdToken as unknown as string
  }
  public set userIdToken(value: string | null) {
    this._userIdToken = value as unknown as RemovableRef<string>
  }
  private _userRefreshToken = useSessionStorage('user_refresh_token', null) as unknown as RemovableRef<string>
  public get userRefreshToken(): string {
    return this._userRefreshToken as unknown as string
  }
  public set userRefreshToken(value: string | null) {
    this._userRefreshToken = value as unknown as RemovableRef<string>
  }
  private _userRole = useSessionStorage('userRole', {} as Role)//The role of the user in selected workspace
  public get userRole(): Role {
    return this._userRole as unknown as Role
  }
  public set userRole(value: Role | null) {
    this._userRole = value as unknown as RemovableRef<Role>
  }
  private _userWorkspace = useSessionStorage('user_workspace', {} as Workspace)//The current user workspace
  public get userWorkspace(): Workspace {
    return this._userWorkspace as unknown as Workspace
  }
  public set userWorkspace(value: Workspace | null) {
    this._userWorkspace = value as unknown as RemovableRef<Workspace>
  }
  private _userWorkspaces = useSessionStorage('user_workspaces', [] as Workspace[])//Contains the list of workspaces to which a user belongs
  public get userWorkspaces(): Workspace[] {
    return this._userWorkspaces as unknown as Workspace[]
  }
  public set userWorkspaces(value: Workspace[]) {
    this._userWorkspaces = value as unknown as RemovableRef<Workspace[]>
  }
  private _workspaceRoles = useSessionStorage('workspace_roles', [] as Role[])//Contains the role of the logged in user for each workspace
  public get workspaceRoles(): Role[] {
    return this._workspaceRoles as unknown as Role[]
  }
  public set workspaceRoles(value: Role[]) {
    this._workspaceRoles = value as unknown as RemovableRef<Role[]>
  }
  private _isWorkspaceSet = useSessionStorage('isWorkspaceSet', true)//Indicates whether the user has ever selected a workspace
  public get isWorkspaceSet(): boolean {
    return this._isWorkspaceSet as unknown as boolean
  }
  public set isWorkspaceSet(value: boolean) {
    this._isWorkspaceSet = value as unknown as RemovableRef<boolean>
  }
  private _serverTimestampDifference = useSessionStorage('server_timestamp_difference', null as unknown as number) as unknown as RemovableRef<number>//Required for verify token
  public get serverTimestampDifference(): number {
    return Number(this._serverTimestampDifference)
  }
  public set serverTimestampDifference(value: number) {
    this._serverTimestampDifference = value as unknown as RemovableRef<number>
  }
  private _saveScreenerId = useSessionStorage('saveScreenerId', null) as unknown as RemovableRef<string>
  public get saveScreenerId(): string {
    return this._saveScreenerId as unknown as string
  }
  public set saveScreenerId(value: string | null) {
    this._saveScreenerId = value as unknown as RemovableRef<string>
  }
  //#endregion property

  public get isLoggedIn(): boolean {
    return this.loggedInUser !== null && this.loggedInUser.id !== undefined// removed this part because we have automatic refresh token && TokenHelper.isIdTokenValid(this.userIdToken, this.serverTimestampDifference)
  }

  public getWorkspaceId(): string {
    return (this.isLoggedIn && this.userWorkspace) ? this.userWorkspace.id as string : ''
  }

  /**
     * Clear session storage and redirect to login page
     */
  public logout(): void {
    this.loggedInUser = null
    this.userIdToken = null
    this.userRefreshToken = null
    this.userRole = null
    this.userWorkspace = null
    this.userWorkspaces = []
    this.workspaceRoles = []
    this.isWorkspaceSet = true
  }

  public logoutAllAccounts(): void {
    this.loggedUsers = [] 
    this.logout()
  }

  public changeLogin(item: { researcher: Researcher, token: string, refreshToken: string }): void {
    this.loggedInUser = item.researcher
    this.userIdToken = item.token
    this.userRefreshToken = item.refreshToken
    this.userRole = null
    this.userWorkspace = null
    this.userWorkspaces = []
    this.workspaceRoles = []
    this.isWorkspaceSet = true
  }

  /**
     * Increment API Request count and update showLoading state
     */
  public incrementApiRequestInProgressCount(): void {
    this.apiRequestInProgressCount = this.apiRequestInProgressCount + 1
    this.showLoading = true
  }

  /**
     * Decrement API Request count and update showLoading state
     */
  public decrementApiRequestInProgressCount(): void {
    this.apiRequestInProgressCount = this.apiRequestInProgressCount - 1
    if (this.apiRequestInProgressCount <= 0) {
      this.apiRequestInProgressCount = 0
      this.showLoading = false
    }
  }

  public hasWorkspacePrivileges(privileges: string[], defaultAllow = false): boolean {
    if (privileges === void 0 || privileges === null || privileges.length === 0) {
      return true
    }
    
    if (!this.isLoggedIn) {
      return false
    }

    for (const privilege of privileges) {
      if (! this.userRole || !RoleHelper.hasPrivilege(privilege, this.userRole.permissions, defaultAllow)) {
        return false
      }
    }

    return true
  }

  public requiresWorkspacePrivileges(privileges: string[], currentUrl?: string): void {
    if (currentUrl === void 0 || currentUrl === null || currentUrl.length === 0) {
      currentUrl = decodeURIComponent(window.location.pathname)
    }

    if (!this.hasWorkspacePrivileges(privileges)) {
      this.logout()
    }
  }
}

//const globalInstance = new GlobalsService()
const globalInstance = reactive(new GlobalsService()) as GlobalsService
export { globalInstance } 