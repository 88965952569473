import { ConduxService } from '@/services/condux.service'
import {
  ConduxApiCommonV1Response,
} from '@conduxio/types'

export class StudyV2Service extends ConduxService {

  protected modelNameSingular = 'study-v2'
  protected modelNamePlural = 'studies-v2'

  public read(id: string, withoutWorkspaceId = false, workspaceId = '', include = ''): Promise<ConduxApiCommonV1Response> {
    const apiRequest = {url: this.modelNameSingular + '/' + id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, include:include }

    const ret = super.get<ConduxApiCommonV1Response>(apiRequest)

    return ret
  }
}
