import { ConduxService } from '@/services/condux.service'
import {
  ConduxApiCommonV1Response,
  ConduxApiResearcherV1SessionParticipantPutResponse,
  SessionParticipant,
  SessionHelper,
} from '@conduxio/types'

export class SessionParticipantService extends ConduxService {

  protected modelNameSingular = 'session-participant'
  protected modelNamePlural = 'session-participants'

  public update(model: SessionParticipant, sessionType:'zoom' | 'survey'| 'eye_tracking', withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1SessionParticipantPutResponse> {
    const validationResult = SessionHelper.validateParticipant(model, sessionType)

    if (!validationResult.valid) {

      return new Promise<ConduxApiResearcherV1SessionParticipantPutResponse>((resolve) => {
        resolve({
          status: 'ERROR',
          errorMessage: 'Error updating SessionParticipant, ' + validationResult.invalidReason,
        } as ConduxApiResearcherV1SessionParticipantPutResponse)
      })
    }

    const apiRequest = {url: this.modelNameSingular + '/' + model.id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, object: { sessionParticipant: validationResult.sessionParticipant }, modelId: model.id }

    return super.put<ConduxApiResearcherV1SessionParticipantPutResponse>(apiRequest)
  }

  public remove(id: string, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiCommonV1Response> {
    const apiRequest = {url: this.modelNameSingular + '/' + id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, modelId: id }

    return super.delete(apiRequest)
  }
}
