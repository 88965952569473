<template >
  <v-tooltip location="bottom">
    <template #activator="{ props }">
      <v-icon
        size="small"  
        class="mx-2"
        v-bind="props"
      >
        {{ $icons.info }}
      </v-icon>
    </template>
    <span>{{ message }}</span>
  </v-tooltip>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InfoBase',
  props: {
    location: {
      type: String,
    },
    message: {
      type: String,
    },
  },
})
</script>
