/* eslint-disable @typescript-eslint/no-unused-vars */
// 
import icons from '@/static/icons'
import colors from '@/static/colors'
import router from '@/router'
import { isEqual, cloneDeep } from "lodash";

import { RouteLocationRaw } from 'vue-router'

export class Utilities {
  scrollToTop(): void {
    window.scrollTo(0,0)
  }

  // #region route
  back(route = ''): void {
    if (router.currentRoute.value.query['back']) {
      router.push(router.currentRoute.value.query['back'] as RouteLocationRaw)
    } else if (route) {
      router.push({ name: route })
    } else {
      router.go(-1)
    }
  }
  // #endregion route

  // #region Image Functions
  public fileSizeToString(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']

    //bytes =  (Math.round((bytes/1024) * 100) / 100)
    if (bytes <= 0) return '0 Byte'
    const i: number = Math.floor(Math.log(Number(bytes)) / Math.log(1024))

    return Math.round((bytes / Math.pow(1024, i)) * 100 / 100) + ' ' + sizes[i]
  }

  public avatarImage(name: string, backgroundColor?: string, useInitialLetters = true, letterNumber = 2): string {
    // creates an avatar image for the user, with his name initial letters and a specified or random background-color
    // returns the image url

    const base_url = 'https://dummyimage.com/'      // OLD https://via.placeholder.com/
    const outputformat = 'png'
    const size = 300

    let foregroundColor = '#000000'

    // if backgroundColor is missing, choose a random color from palette
    if (!backgroundColor || backgroundColor === null) backgroundColor = colors[Math.floor(Math.random() * colors.length)]

    // selects the foregroundColor (black or white) based on the backgroundColor
    const theshold = 160
    const hex = backgroundColor.replace('#', '')
    const r = parseInt(hex.substr(0, 2), 16)
    const g = parseInt(hex.substr(2, 2), 16)
    const b = parseInt(hex.substr(4, 2), 16)
    const hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b),
    )

    // sets the foreground color
    if (hsp > theshold)
      foregroundColor = '#000000'
    else
      foregroundColor = '#ffffff'

    // calculates the name initial letters
    if (useInitialLetters) {
      // uses the initial letters of the name
      name = name.trim().toUpperCase()
      if (name === '') {
        name = ':'
      } else {
        const d = name.toUpperCase().split(' ')
        const i = d.map((item) => {
          return item.substring(0, 1)
        })

        name = i.join('').substring(0, letterNumber)
      } 
    } else {
      // uses the full name
      name = name.trim().toLowerCase()
    }

    return `${base_url}/${size}.${outputformat}/${backgroundColor.replace('#', '')}/${foregroundColor.replace('#', '')}/?text=${name}`
  }
  // #endregion Image Functions

  // #region Location
  public isLocalhost(): boolean {
    return (location.host.indexOf('localhost') === 0)
  }
  // #endregion Location

  // #region Text Functions
  public stripHTML(text: string): string {
    // returns the project's description as simple plain text
    text = text ?? ''

    return text.replace(/<[^>]*>?/gm, ' ').replace(/\s\s/gm, ' ')
  }
  public previewProjectDescription(text: string): string {
    /* returns the project's description, by changing
      headers, paragraphs, links, blockquotes
      into simple text
    */
    let d = text ?? ''

    d = d.replace(/<\/?h1[^>]*>/g, ' ')               // removes headers
    d = d.replace(/<\/?h2[^>]*>/g, ' ')
    d = d.replace(/<\/?h3[^>]*>/g, ' ')
    d = d.replace(/<\/?h4[^>]*>/g, ' ')
    d = d.replace(/<\/?h5[^>]*>/g, ' ')
    d = d.replace(/<\/?h6[^>]*>/g, ' ')
    d = d.replace(/<\/?p[^>]*>/g, ' ')                // removes paragraph
    d = d.replace(/<\/?a[^>]*>/g, ' ')                // removes links
    d = d.replace(/<\/?blockquote[^>]*>/g, ' ')       // removes blockquote
    d = d.replace(/<\/?iframe[^>]*>/g, ' ')           // removes iframe
    d = d.replace(/<img .*?>/g, ' ')                  // removes img          DOESN'T works!!!

    return d
  }
  public truncate(value: string, maxLength: number): string {
    // truncates a string to the given max length
    if (maxLength <= 0) 
      return value
      
    return (value.length > maxLength) ? value.slice(0, maxLength - 1).trim() + '...' : value
  }
  public getWordCount(value: string): number {
    return value.trim().split(' ')
      .filter((n) => { return n !== '' })
      .length
  }
  public capitalizeSentence(value: string): string {
    return value.replace(/\b\w/g,  (l) => { return l.toUpperCase() })
  }
  // #endregion Text Functions 

  // #region Object Functions
  purgeObject(object: any, ...purgeValues: any): any {
    // This purges the given object, by deleting every propertiy having a value specified in the purgeValues list
    // It purges recoursively, sub-objects and also array
    // #region Readme
    /*
    const user = {
      name: 'Simone',
      surname: null,
      address: {
        street: 'Via Cavazzi, 21',
        zip:41013,
        City:null,
        Province: 'MO',
      },
      color: 'black',
    }
    // delete all null properties and all the properties having 'black' value
    **/
    // #endregion Readme

    if (typeof object === 'object') {
      // type object: purges every property
      const sourceObject = { ...object }
    
      for (const [key, value] of Object.entries(sourceObject)) {
        if (purgeValues.includes(value)) {
          // value to purge
          delete sourceObject[key]
        } else {
          // valid value: we don't have to purge it. Checks if this is an array or an object
          if (typeof value === 'object') {
            // this is an array, we have to purge each item
            if (Array.isArray(value)) {
              // eslint-disable-next-line no-unused-vars
              value.forEach((item) => {
                // eslint-disable-next-line no-unused-vars
                item = this.purgeObject(item, ...purgeValues)
              })
            } else {
              // this is an object, we have to purge it too
              sourceObject[key] = this.purgeObject(sourceObject[key], ...purgeValues)
            }
          }
        }
      }

      return sourceObject
    } else {
      // other data type (e.g string, number...)
      return object
    }  
  }
  // #endregion Object Functions

  // #region Time Functions
  public timestampToFormatTime(timestamp: number): string {
    return new Date(timestamp).toISOString().substring(11, 19)
  }
  public timestampToFormatDatetime(timestamp: number): string {
    return new Date(timestamp).toDateString() + ' ' + this.timestampToFormatTime(timestamp)
  }
  public secondToFormatTime(second: number, short = false): string {
    let retVal = this.timestampToFormatTime(second * 1000)

    if (short) {    // removes 00 hours
      if (retVal.startsWith('00:')) retVal = retVal.substring(3)
    }

    return retVal
  }
  // #region Time Functions

  // #region Generic Functions
  public getRandomColor(): string {
    return '#' + (Math.random() * 0xFFFFFF << 0).toString(16)
  }
  public deepCloneWithRef(source: any, destination: any): any {
    for (const key in source) {
      destination[key] = source[key]
    }
  }
  public deepClone(obj: any): any {
    return cloneDeep(obj)
  }
  public equals(obj1: any, obj2: any): any {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  }
  public isEqual(obj1: any, obj2: any): any {
    return isEqual(obj1, obj2)
  }
  public getSortIcon(property: any, orderName: string): string {
    if (property.orderByName !== orderName) {
      return icons.sort
    } else {
      if (property.orderByDirection === 'ASC') {
        return icons.sort_up
      } else {
        return icons.sort_down
      }
    }
  }
  public getBackgroundStyle(color: string): string {
    let css = 'background-color:' + color + ';'

    if (color.colorIsLight()) {
      css += 'color:black;'
    } else {
      css += 'color:white;'
    }

    return css
  }
  public getShadesOfColor(color: string, num: number):  string[] {
    const ret = [] as string []
    const isLight = color.colorIsLight(190)
    const inc = 50
    let col = ''

    for (let i = 0;i < num;i++) {
      if (isLight) {
        col = this.colorShade(color, -(inc * (i + 1))) 
      } else {
        col = this.colorShade(color, (inc * (i + 1)))  
      }
      ret.push(col)
    }

    return ret
  }
  public colorShade(color: string, amt: number): string {
    color = color.replace(/^#/, '')
    if (color.length === 3) color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2]
    const [rS, gS, bS] = color.match(/.{2}/g) as any

    let [r, g, b] = [parseInt(rS, 16) + amt, parseInt(gS, 16) + amt, parseInt(bS, 16) + amt]

    const soglia = 40

    r = Math.max(Math.min(255 - soglia, r), soglia)
    g = Math.max(Math.min(255 - soglia, g), soglia)
    b = Math.max(Math.min(255 - soglia, b), soglia)

    // convert into hex number
    const rh = r.toString(16)
    const gh = g.toString(16)
    const bh = b.toString(16)

    const rr = (rh.length < 2 ? '0' : '') + rh
    const gg = (gh.length < 2 ? '0' : '') + gh
    const bb = (bh.length < 2 ? '0' : '') + bh

    return `#${rr}${gg}${bb}`
  }
  copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text).then(() => {
      //set notification
    })
  }
  openInNewWindow(text: string): void {
    window.open(text, '_blank', 'noreferrer')
  }
  mailTo(emailAddress: string): void {
    window.open(`mailto:${emailAddress}`)
  }
  isValidEmail(emailAddress: string): boolean {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return pattern.test(emailAddress)
  }
  isValidDomain(domain: string): boolean {
    const pattern = /((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return pattern.test(domain)
  }
  // #endregion Generic Functions

  public getMethods = (obj: any): any[] => Object.getOwnPropertyNames(obj).filter((item) => typeof obj[item] === 'function')

  resizeCanvas(canvas: any, max_size: number): string {
    if (canvas.width > canvas.height) {
      if (canvas.width > max_size) {
        canvas.height *= max_size / canvas.width
        canvas.width = max_size
      }
    } else {
      if (canvas.height > max_size) {
        canvas.width *= max_size / canvas.height
        canvas.height = max_size
      }
    }
    const ctx = canvas.getContext('2d')
    const img = new Image()

    img.src =  canvas.toDataURL()

    ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

    return canvas.toDataURL()
  }

  public checkOperator(operator: 'AND' | 'OR' | undefined, params: boolean[]): boolean {
    let isValid = true

    switch (operator) {
      case 'AND':
        isValid = true
        params.forEach((param) => {
          isValid = param && isValid
        })
        break
      case 'OR':
        isValid = false
        params.forEach((param) => {
          isValid = param || isValid
        })
        break
    }

    return isValid
  }

  // public mapClass<T>(obj: Partial<T>, ctor: new () => T): T {
  //   const instance = new ctor();
  //   return Object.assign(instance as any, obj) as T
  // }
}