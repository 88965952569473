<template>
  <div>
    <!-- left bar -->
    <LeftBar v-if="$globalsService.isLoggedIn" v-model="drawer" />

    <!-- Pages -->
    <v-main>
      <div class="pa-xl-3 pa-lg-3 pa-md-3 pa-sm-2 pa-1">
        <!-- Top bar -->
        <TopBar @toggle-left="drawer=!drawer"/>
        <LoaderBase/>
        <slot></slot>
      </div>
    </v-main>
    <!-- Footer -->
    <FooterBar/>
  </div>
</template>

<script lang="ts">
// 
import { defineComponent } from 'vue'

// components
import FooterBar from '@/layouts/Components/FooterBar.vue'
import LeftBar from '@/layouts/Components/LeftBar.vue'
import TopBar from '@/layouts/Components/TopBar.vue'

import { useMessageBoxStore } from '../store/message-box'

export default defineComponent({
  name: 'MainLayout',
  components: {
    FooterBar,
    LeftBar,
    TopBar,
  },
  setup() {
    const messageBox = useMessageBoxStore()   // declare the mb's store
    
    window.stores = { messageBox }        // initialize the mb store

    return {
      messageBox,
    }
  },

  // #region Properties
  data() {
    return {
      drawer: false,
    }
  },
  computed: {
  },
  watch: {

  },
  // #endregion Properties

  // #region Life Cycle
  created() {
    this.drawer = !this.$vuetify.display.xs
  },
  // #endregion Life Cycle

  methods: {

  },
})
</script>

<style lang="scss">
$footerheight : 100px;
@media (max-width: 1280px)
{
  .v-main .v-main__wrap
  {
    min-height: calc(100vh - #{$footerheight} - 26px) !important;
  }
}
@media (max-width: 599px)
{
  .v-main {
    margin-bottom: $footerheight;
  }
}
.v-main .v-main__wrap
{
  min-height: calc(100vh - #{$footerheight} - 65px);
}
@media (min-width: 600px)
{
    .v-main 
    {
        padding-top:64px!important;
    }
}
@media (max-width: 599px)
{
    .v-main 
    {
        padding:0!important;
        display:inline-block; 
        margin-top:64px;
        width: 100%;
    }
}
</style>
