import { ConduxService } from '@/services/condux.service'
import {
  ConduxApiCommonV1Response,
  ConduxApiResearcherV1RoleGetResponse,
  ConduxApiResearcherV1RolePostResponse,
  ConduxApiResearcherV1RolePutResponse,
  ConduxApiResearcherV1RolesGetRequest,
  ConduxApiResearcherV1RolesGetResponse,
  Role,
  RoleHelper,
} from '@conduxio/types'

export class RoleService extends ConduxService {

  protected modelNameSingular = 'role'
  protected modelNamePlural = 'roles'

  public read(id: string, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1RoleGetResponse> {
    const apiRequest = {url: this.modelNameSingular + '/' + id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId }

    return super.get<ConduxApiResearcherV1RoleGetResponse>(apiRequest)
  }

  public readAll(request: ConduxApiResearcherV1RolesGetRequest, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1RolesGetResponse> {
    const apiRequest = {url: this.modelNameSingular, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, request: request }

    return super.get<ConduxApiResearcherV1RolesGetResponse>(apiRequest)
  }
  
  public create(model: Role, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1RolePostResponse> {
    model.workspaceId = this.globalsService.getWorkspaceId()
    const validationResult = RoleHelper.validate(model)

    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1RolePostResponse>((resolve) => {
        resolve({
          status: 'ERROR',
          errorMessage: 'Error creating Role, ' + validationResult.invalidReason,
        } as ConduxApiResearcherV1RolePostResponse)
      })
    }

    const apiRequest = {url: this.modelNameSingular, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, object: { role: validationResult.role } }

    return super.post<ConduxApiResearcherV1RolePostResponse>(apiRequest)
  }

  public update(model: Role, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1RolePutResponse> {
    model.workspaceId = this.globalsService.getWorkspaceId()
    const validationResult = RoleHelper.validate(model)

    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1RolePutResponse>((resolve) => {
        resolve({
          status: 'ERROR',
          errorMessage: 'Error updating Role, ' + validationResult.invalidReason,
        } as ConduxApiResearcherV1RolePutResponse)
      })
    }

    const apiRequest = {url: this.modelNameSingular + '/' + model.id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, object: { role: validationResult.role }, modelId: model.id }

    return super.put<ConduxApiResearcherV1RolePutResponse>(apiRequest)
  }

  public remove(id: string, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiCommonV1Response> {
    const apiRequest = {url: this.modelNameSingular + '/' + id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, modelId: id }

    return super.delete<ConduxApiCommonV1Response>(apiRequest)
  }
}
