import { Utilities } from '@/helpers/utilities'
import { ConduxService } from '@/services/condux.service'
import {
  ConduxApiResearcherV1TemplateGetResponse,
  ConduxApiResearcherV1TemplatesGetRequest,
  ConduxApiResearcherV1TemplatesGetResponse,
  ConduxApiResearcherV1TemplatePutResponse,
  ConduxApiResearcherV1TemplateDeleteResponse,
  ConduxApiResearcherV1TemplateVariablesGetResponse,
  Template,
} from '@conduxio/types'

export class TemplateService extends ConduxService {

  protected modelNameSingular = 'template'
  protected modelNamePlural = 'templates'

  public readAll(request: ConduxApiResearcherV1TemplatesGetRequest, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1TemplatesGetResponse> {
    const apiRequest = {url: this.modelNameSingular, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, request: request }

    return super.get<ConduxApiResearcherV1TemplatesGetResponse>(apiRequest)
  }

  public updateTemplate(model: Template, language: string, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1TemplatePutResponse> {
    const util = new Utilities()
    const modelCopy = util.deepClone(model)

    modelCopy.body = modelCopy.body.toBase64()

    const apiRequest = {url: this.modelNameSingular + '/' + model.event + '/' + model.templateType + '/', withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, object: { template: modelCopy }, modelId: model.id, apiRequest: { languageCode: language } }

    return super.put<ConduxApiResearcherV1TemplatePutResponse>(apiRequest)
  }

  public remove(id: string, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1TemplateDeleteResponse> {
    const apiRequest = {url: this.modelNameSingular + '/' + id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, modelId: id }

    return super.delete<ConduxApiResearcherV1TemplateDeleteResponse>(apiRequest)
  }

  // New API: template-get requires "event" and "templateType" as path-parameters
  // - the workspace_id is alsways required
  // - doesn't have query-parameters
  public async getTemplate(eventName: string, templateType: string): Promise<ConduxApiResearcherV1TemplateGetResponse> {
    const url = `${this.modelNameSingular}/${eventName}/${templateType}`
    const apiRequest = {url: url, withoutWorkspaceId:false, workspaceId:'', request: null }

    return super.get<ConduxApiResearcherV1TemplateGetResponse>(apiRequest)
  }

  public async getTemplateVariables(templateName: string, language: string,   withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1TemplateVariablesGetResponse> {
    const apiRequest = {url: this.modelNameSingular + '/variables/' + templateName, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, request: {languageCode: language}, modelName: 'template variables' }

    return super.get<ConduxApiResearcherV1TemplateVariablesGetResponse>(apiRequest)
  }

  public getPreview(model: Template, language: string, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1TemplatePutResponse> {
    const util = new Utilities()
    const modelCopy = util.deepClone(model)

    modelCopy.body = modelCopy.body.toBase64()

    const apiRequest = {url: this.modelNameSingular + '/preview/' + model.event + '/' + model.templateType + '/', withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, object: { template: modelCopy }, modelId: model.id, apiRequest: { languageCode: language } }

    return super.put<ConduxApiResearcherV1TemplatePutResponse>(apiRequest)
  }
}
