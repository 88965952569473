export default {
  required: (v: string): any => !!v || 'Field Required.',
  required_array: (v: string[]): any => (v && v.length > 0) || 'Field Required.',
  requiredFile: (v: string): any => (!!v && v.length > 0) || 'Field Required.',
  requiredWithParam(v: string): any { return !!v || 'Field Required.'},
  email: (v: string): any => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return pattern.test(v) || 'Invalid e-mail.'
  },
  min(num: number): any {
    return (v: string) => (v || '').length >= num || `Min ${num} characters`
  },
  minNumber(num: number): any {
    return (v: number) => v >= num || `Min value is ${num}`
  },
  minWithVal(num: number): any {
    return (v: string) => {
      (v || '').length >= num || `Min ${num} characters`
    }
  },
  minWithParam(v: string, num: number): any {
    return (v || '').length >= num || `Min ${num} characters`
  },
  max(num: number): any {
    return (v: string) => (v || '').length <= num || `Max ${num} characters`
  },
  maxNumber(num: number): any {
    return (v: number) => v <= num || `Max value is ${num}`
  },
  upper: (v: string): any => {
    const pattern = /[A-Z]{1}/

    return pattern.test(v) || 'At least one uppercase character'
  },
  lower: (v: string): any => {
    const pattern = /[a-z]{1}/

    return pattern.test(v) || 'At least one lowercase character'
  },
  digit: (v: string): any => {
    const pattern = /\d/

    return pattern.test(v) || 'At least one digit'
  },
  special: (v: string): any => {
    const pattern = /[^A-Za-z0-9]/

    return pattern.test(v) || 'At least one special character'
  },
  matchingPasswords(field: string): any {
    // rule to check if a password matches the confirmation password
    return (v: string) => v === field || 'Password does not match'
  },
  dateMajorOfCurrent: (start: string): any => {
    const DateStart = new Date(start)
    const CurrentDate = new Date()    

    CurrentDate.setHours(0, 0, 0, 0)

    return (DateStart >= CurrentDate) || 'Date must be greater or equal then current date'
  },
  dateHigherThan(start: string): any {
    // rule to check if start date is higher or equal to the given (compareDate) date
    return (compareDate: string) => {
      const DateStart = new Date(start)
      const DateEnd = new Date(compareDate)
   
      return ((!start || !compareDate || DateEnd >= DateStart)) || 'Date must be greater or equal to ' + DateStart.toDateString()
    }
  },
  dateHigherOrEqual: (start: string): any => {
    // rule to check if start date is higher or equal to today's date
    const DateStart = new Date(start)
    const CurrentDate = new Date()
    
    CurrentDate.setHours(0, 0, 0, 0)

    return (DateStart >= CurrentDate) || 'Date must be greater or equal to current date'
  },
  custom: (condition: boolean, message: string): any => {
    return condition || message
  },

  tags: (tags: string[]): any => {
    if (tags.length > 3)
    {
      return 'Maximum number of tags is 3'
    }
    for (const tag of tags) {
      if(tag.length > 12)
      {
        return `Invalid tag ${tag}, max chars number is 12`
      }
      const pattern = /^[A-Za-z0-9\-_]/

      if (!pattern.test(tag))
      {
        return `Invalid tag ${tag}, can only contain these special characters '-'  '_'`
      }
    }
    return true
  },
  domainList: (value: string): any => {
    const pattern = /^([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\s*,\s*([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,})*(\s*,\s*)?$/

    if (! pattern.test(value)) {
      return 'be sure to insert a valid list domains separated by a comma'
    }

    return true
  }
}