<template>
  <cropper
    ref="cropper"
    :src="image"
    :stencil-props="stencilProperties"
    :default-size="defaultSize"
    :stencil-component="stencilComponent"
  />
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import 'vue-advanced-cropper/dist/theme.bubble.css'
import CircleStencil from '@/components/tools/CircleStencil.vue'

export default defineComponent({
  name: 'CropperComponent',
  components: {
    Cropper,
  },
  props: {
    image: {
      type: String,
    },
    isCircle: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: Number,
    },
  },
  computed: {
    stencilProperties() {
      const handlers = {
        eastNorth: true,
        north: true,
        westNorth: true,
        west: true,
        westSouth: true,
        south: true,
        eastSouth: true,
        east: true,
      }

      const ret = {
        handlers: handlers,
        aspectRatio: this.aspectRatio,
        movable: true,
        resizable: true,
      }

      return ret
    },
    stencilComponent() {
      if (this.isCircle)
        return CircleStencil
      else
        return undefined
    },
  },
  watch: { 
  },
  methods: {
    getCropImage(): any
    { const { canvas } = (this.$refs.cropper as any).getResult()
      return canvas
    },
    getCroppedImageData(): string
    { const { canvas } = (this.$refs.cropper as any).getResult()
      return canvas.toDataURL()
    },
    defaultSize({ imageSize , visibleArea }: any) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height,
      }
    },  
  },
})
</script>

<style lang="scss">
.vue-advanced-cropper {
  overflow: hidden!important;
}
</style>