/*
//import  firebase  from "firebase/app";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, Messaging, isSupported } from "firebase/messaging";
import { getAnalytics, logEvent  } from "firebase/analytics";
import { globalInstance } from '../services/globals.service'

const firebaseConfig = {
  apiKey: "AIzaSyDaeQYoXFgsVKXZtXKtnY2_zyLTDu4aZjI",
  authDomain: "condux-6122e.firebaseapp.com",
  projectId: "condux-6122e",
  storageBucket: "condux-6122e.appspot.com",
  messagingSenderId: "187738701955",
  appId: "1:187738701955:web:3c89e036a1aca795769bb6",
  measurementId: "G-Y5X0RG46J4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app)
logEvent(analytics, 'notification_received');
const messaging = getMessaging(app) as Messaging

const setToken = async function(): Promise<void>
{
  const VAPID_KEY = "BP8H3krygEvMU6KbQTZHY1qG9n3OUjrXtTbTELFT6zFOEmdLYVN-50W1bMguPhHpVm3HzymsSUv0aDfNoK2JwNg";
  globalInstance.pushNotificationToken = await getToken(messaging, { vapidKey: VAPID_KEY });
}

const requestPermission = async function(): Promise<void>
{
  if (await isSupported())
  {
    await Notification.requestPermission().then(async function(permission) 
    { 
        if (permission === 'granted') {
          // Notification permission granted.
          
          await setOnMessageEvent();
        } else {
          //do stuff if need when user decline push notifications
        }
    })
  }
}

const setOnMessageEvent = async function(): Promise<void>
{
  if(Notification.permission === 'granted')
  {
    if (!globalInstance.pushNotificationToken)
    {
      setToken()    
    }
  }
  try {   
    
    if (globalInstance.pushNotificationToken) {
      // eslint-disable-next-line no-unused-vars
      onMessage(messaging, (_message) => {
        //new Notification(message.notification.title, { body: message.notification.body });
      });
    } else {
      // Need to request permissions to show notifications.     
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Unable to get messaging token.', error);
  }
}

export { requestPermission, setOnMessageEvent }
**/

// 2024-06-06 SIMONE: E2E test cannot work until we fix firebase's service-worker
const requestPermission = async function(): Promise<void> { 
  // eslint-disable-next-line
  console.log('') 
}
const setOnMessageEvent = async function(): Promise<void> { 
  // eslint-disable-next-line
  console.log('') 
}
export { requestPermission, setOnMessageEvent }