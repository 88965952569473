<template>
  <v-dialog
    v-model="dialogs.tos"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
    class="bg-color4"
  >
    <v-card>
      <v-card-title
        dark
        class="primary bg-primary text--lighten-5"
      >
        CondUX Terms of Service
        <v-spacer/>
        <v-btn
          dark
          color="primaryA"
          variant="text"
          :icon="$icons.close"
          rounded
          depressed
          @click="dialogs.closeTermsAndConditions()"
        />
      </v-card-title>
      <TermsOfService />
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { useDialogsStore } from '../../store/dialogs'
import { defineComponent, defineAsyncComponent } from 'vue'

export default defineComponent({
  name: 'TosDialog',
  components: {
    TermsOfService: defineAsyncComponent(() => import('@/views/NotLogged/TermsOfService.vue'))
  },
  setup(): any {
    // Destructure only the keys we want to use
    const dialogs = useDialogsStore()   // declare the toast's store
    
    window.stores = { dialogs }         // initialize the store

    return { dialogs }
  },
})
</script>

<style scoped>
.subtext--container {
  padding: 12px;
  border: 1px solid #c0c0c0;
  background-color: #f2f2f2;
  font-size: 0.9rem;
}
</style>
