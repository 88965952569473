/*
  ./store/message-box.ts
  message-box store
**/
import { defineStore } from 'pinia'

// #region Interfaces
export interface ButtonConfig {
  // Button object
  text: string;
  key: string;
  icon?: string;
  color?: string;
  button?: boolean;
}
export interface LinkConfig {
  // link object
  text?: string;
  url: string;
  self?: boolean;
}
export interface InputConfig {
  // input object
  label?: string;
  placeholder?: string;
  value?: string;
  type?: string;
}
export interface MessageConfig {
  // message object
  mode?: string;
  title: string;
  message: string;
  subMessage?: string;
  errorMessage?: string;
  icon?: string;
  buttons?: ButtonConfig [];
  canCancel?: boolean;
  large?: boolean;
  details?: string[];
  showDetails?: boolean;
  link?: LinkConfig;
  input?: InputConfig;
  dontShowAgain?: string;
  detailsTitle?: string;
}
export interface MessageCallback{
  (result: string): void;     // eslint-disable-line no-unused-vars
}
// #endregion Interfaces

// #region Store
export type RootState = {
  display: boolean;
  message: MessageConfig;
  callback: MessageCallback;
};

export const useMessageBoxStore = defineStore({
  id: 'message-box',
  state: () =>
    ({
      display: false,

      message: {
        mode: 'message-box',
        title: '',
        message: '',
        subMessage: '',
        errorMessage: '',
        icon: '',
        buttons: [],
        canCancel: false,
        large: false,
        details: [],
        showDetails: false,
        link: {
          text: '',
          url: '',
          self: false,
        },
        input: {
          label: '',
          placeholder: '',
          value: '',
        },
      },
      callback: (_result: string) => undefined,    // eslint-disable-line no-unused-vars
    } as RootState),

  // actions
  actions: {
    // Show simple toast
    show(payload: MessageConfig, callbackFunction?: MessageCallback) {

      // cancel/not previsous message box
      if (this.display) {
        if (this.message.canCancel) {
          this.display = false
        } else {
          return
        }
      }

      // normalizes payload
      payload = normalizeMessageProperties(payload)

      // checks don't show again previously given answer
      if (payload.dontShowAgain !== '') {
        const defValue = localStorage.getItem('message-box.' + payload.dontShowAgain)

        if (defValue !== null) {
          // we have a previous answer to the same message:
          // returns the previous answer without showing message
          if (callbackFunction) callbackFunction(defValue)

          return
        }
      }
      
      // show MessageBox
      setTimeout(() => {
        this.message = payload
        this.callback = (result: string): any => { // eslint-disable-line no-unused-vars
          if (callbackFunction) callbackFunction(result)

          return null
        }
        this.display = true
      }, 250)
    },
  },
})
// #endregion Store

// #region Utils
function normalizeMessageProperties(payload: MessageConfig): MessageConfig {
  // normalizes message properties
  const ret: MessageConfig = { ...payload }

  // sets empty properties to default values
  if (!ret.errorMessage) ret.errorMessage = ''
  if (!ret.subMessage) ret.subMessage = ''
  if (!ret.icon) ret.icon = ''
  if (!ret.buttons) ret.buttons = []
  if (ret.canCancel === undefined) ret.canCancel = false
  if (ret.large === undefined) ret.large = false
  if (!ret.details) ret.details = []
  if (ret.showDetails === undefined) ret.showDetails = false
  if (!ret.dontShowAgain) ret.dontShowAgain = ''

  // normalizes some variables
  ret.title = ret.title.trim()
  ret.message = ret.message.trim()
  ret.errorMessage = ret.errorMessage.trim()
  ret.subMessage = ret.subMessage.trim()
  ret.dontShowAgain = ret.dontShowAgain.trim().toLowerCase()

  // sets default buttons, if missing
  if (ret.buttons.length === 0) {
    ret.buttons.push({
      text: 'OK',
      key: 'ok',
      icon: '',
      button: true,
    })
  } else {
    // checks/normalizes button properties
    ret.buttons.forEach((btn) => {
      if (!btn.icon) btn.icon = ''
      if (btn.button === undefined) btn.button = true

      btn.text = btn.text.trim()
      btn.key = btn.key.trim().toLowerCase()
    })
  }

  // trims details, is specified
  if (ret.details.length > 0) {
    ret.details.forEach((_d) => {
      _d = _d.trim()  // eslint-disable-line no-unused-vars
    })
  } else {
    ret.showDetails = false
  }

  // checks/normalizes link object, if specified
  if (ret.link) { 
    ret.link.url = ret.link.url.trim().toLowerCase()
    if (!ret.link.text) ret.link.text = ret.link.url          // url is the default text
    if (ret.link.self === undefined) ret.link.self = false    // links points _blank by default
  }

  // checks/normalizes input object, if specified
  if (ret.input) { 
    if (ret.input.label)
      ret.input.label = ret.input.label.trim()
    if (!ret.input.placeholder) ret.input.placeholder = ret.input.label  // lable is the default placeholder
    if (ret.input.value) ret.input.value = ret.input.value.trim()
    ret.errorMessage = ''           // no error on input-box-mode
  }

  return ret
}
// #endregion Utils