<template>
  <v-dialog 
    v-model="dialogs.privacy" 
    fullscreen 
    :scrim="false" 
    transition="dialog-bottom-transition" 
    class="bg-color4"
  >
    <v-card-title dark class="primary bg-primary text--lighten-5">
      Privacy Policy
      <v-spacer />
      <v-btn  
        dark 
        color="primaryA" 
        variant="text" 
        :icon="$icons.close" 
        rounded 
        depressed
        @click="dialogs.closePrivacy()" 
      />
    </v-card-title>
    <PrivacyPolicy />
  </v-dialog>
</template>

<script lang="ts">
import { useDialogsStore } from '../../store/dialogs'
import { defineComponent, defineAsyncComponent } from 'vue'

export default defineComponent({
  name: 'PrivacyDialog',
  components: {
    PrivacyPolicy: defineAsyncComponent(() => import('@/views/NotLogged/PrivacyPolicy.vue'))
  },
  setup(): any {
    const dialogs = useDialogsStore()   // declare the toast's store

    window.stores = { dialogs }         // initialize the store

    return { dialogs }
  },
})
</script>

<style scoped></style>
