/* eslint-disable */
interface String {
    colorIsLight(theshold?: number): boolean
}
String.prototype.colorIsLight = function (theshold = 160): boolean{  
    const hex = this.replace('#', '')
    const r = parseInt(hex.substr(0, 2), 16)
    const g = parseInt(hex.substr(2, 2), 16)
    const b = parseInt(hex.substr(4, 2), 16)
    const hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b),
    )
    return hsp > theshold
}