/*
  ./src/static/icons
  defines app icons
  see:  https://materialdesignicons.com/
*/
export default {
  // #region Project Icons
  project: 'mdi-briefcase',
  projects: 'mdi-briefcase',
  project_add: 'mdi-briefcase-plus',
  project_edit: 'mdi-briefcase-edit',
  // #endregion Project Icons

  // #region Preview Icons
  preview_as_text: 'mdi-format-text-variant',
  preview_as_html: 'mdi-file-document-outline',
  // #endregion Preview Icons

  // #region Study Icons
  study: 'mdi-book',
  studies: 'mdi-book',
  study_add: 'mdi-book-plus',
  study_edit: 'mdi-book-edit',
  // #endregion Study Icons

  // #region Questionnaire Icons
  questionnaire: 'mdi-file-document-outline',
  questionnaires: 'mdi-file-document-multiple-outline',
  questionnaire_add: 'mdi-file-document-edit-outline',
  questionnaire_edit: 'mdi-file-document-edit-outline',
  // #endregion Questionnaire Icons

  // #region Questionnaire Icons
  user: 'mdi-account',
  users: 'mdi-account-supervisor',
  user_add: 'mdi-account-plus',
  user_edit: 'mdi-account-edit',
  // #endregion Questionnaire Icons

  // #region Zoom Icons
  zoom: 'mdi-monitor',
  zooms: 'mdi-monitor-multiple',
  zoom_add: 'mdi-monitor-arrow-down',
  zoom_edit: 'mdi-monitor-edit',
  // #endregion Zoom Icons

  // #region Calendar Icons
  calendar: 'mdi-calendar',
  calendars: 'mdi-calendar-blank-multiple',
  calendar_add: 'mdi-calendar-plus',
  calendar_edit: 'mdi-calendar-edit',
  // #endregion Calendar Icons

  // #region Scheduled Icons
  scheduled: 'mdi-timeline-clock-outline',
  scheduled_add: 'mdi-timeline-plus-outline',
  scheduled_edit: 'mdi-timeline-text-outline',
  // #endregion Scheduled Icons

  // #region CRUD icons
  loader: 'mdi-loading',
  reload: 'mdi-reload',
  add: 'mdi-plus',
  edit: 'mdi-pencil',
  edit_outline: 'mdi-pencil-outline',
  view: 'mdi-eye',
  not_view: 'mdi-eye-off',
  delete: 'mdi-delete',
  delete_outline: 'mdi-delete-outline',
  // #endregion CRUD icons

  // #region Tag Icons
  tag: 'mdi-tag',
  tag_add: 'mdi-tag-plus',
  tag_delete: 'mdi-tag-remove',
  tag_check: 'mdi-tag-check',
  // #endregion Tag Icons

  // #region Comment Icons
  comment: 'mdi-comment-outline',
  comment_add: 'mdi-comment-plus',
  comment_delete: 'mdi-comment-minus',
  comment_check: 'mdi-comment-check',
  // #endregion Comment Icons

  // #region Transcription Icons
  tanscription: 'receipt-text',
  transcription_add: 'mdi-receipt-text-plus',
  transcription_delete: 'mdi-receipt-text-remove',
  transcription_check: 'receipt-text-check',
  // #endregion Transcription Icons

  // #region personas
  personas: 'mdi-account-box-outline',
  // #endregion personas

  // #region Sessions icons
  group_completed: 'mdi-account-group',
  group_remaining: 'mdi-account-group-outline',
  schedule: 'mdi-calendar-clock',
  completed: 'mdi-check-circle-outline',
  missed: 'mdi-cancel',
  // #endregion Sessions icons

  // #region Galleries
  image_outline: 'mdi-image-outline',
  images: 'mdi-image-multiple',
  add_image: 'mdi-image-plus',
  // #endregion Galleries

  // #region Pages
  page: 'mdi-card-outline',
  page_add: 'mdi-card-plus-outline',
  page_note: 'mdi mdi-note-text-outline',
  // #endregion Pages

  // #region status
  success: 'mdi-check-circle',
  error: 'mdi-alpha-x-circle',
  warning: 'mdi-alert-circle',
  // #endregion status

  // #region Generals icons
  dashboard: 'mdi-view-dashboard',
  home: 'mdi-home',
  home_edit: 'mdi-home-edit',
  info: 'mdi-information',
  stream: 'mdi-webcam',
  link: 'mdi-link',
  link2: 'mdi-link-variant',
  open_link: 'mdi-open-in-new',
  copy: 'mdi-content-copy',
  back: 'mdi-arrow-left-circle',
  next: 'mdi-arrow-right-circle',
  left: 'mdi mdi-arrow-left-bold-circle-outline',
  right: 'mdi mdi-arrow-right-bold-circle-outline',
  account: 'mdi-account',
  account_multiple: 'mdi-account-multiple',
  logout: 'mdi-logout-variant',
  upload: 'mdi-upload',
  download: 'mdi-download',
  download2: 'mdi-tray-arrow-down',
  filter: 'mdi-filter',
  filters: 'mdi-filter',
  filters_off: 'mdi-filter-off',
  close: 'mdi-close',
  unlock: 'mdi-lock-open-outline',
  lock: 'mdi-lock-outline',
  timeZone: 'mdi-map-clock',
  help: 'mdi-help-circle',
  clock: 'mdi-clock',
  clock_alert: 'mdi-clock-alert',
  email: 'mdi-email',
  microsoft: 'mdi-microsoft',
  google: 'mdi-google',
  searh: 'mdi-magnify',
  menu: 'mdi-menu',
  sun: 'mdi-white-balance-sunny',
  moon:'mdi-weather-night',
  bell: 'mdi-bell',
  bell_outline: 'mdi-bell-outline',
  bell_cog: 'mdi-bell-cog',
  vertical_dots: 'mdi-dots-vertical',
  horizontal_dots: 'mdi-dots-horizontal',
  video: 'mdi-video',
  video_outline: 'mdi-video-outline',
  sort: 'mdi-sort',
  sort_up: 'mdi-sort-descending',
  sort_down: 'mdi-sort-ascending',
  palette: 'mdi-palette',
  clock7: 'mdi-clock-time-seven-outline',
  settings: 'mdi-cog-outline',
  preview: 'mdi-file-find',
  expired: 'mdi-calendar-remove',
  password: 'mdi-key',
  old_password: 'mdi-key-minus',
  new_password: 'mdi-key-plus',
  camera: 'mdi-camera',
  drag: 'mdi-drag',
  drag_horizontal: 'mdi-drag-horizontal',
  true: 'mdi-check',
  addLIst:'mdi-table-column-plus-after',
  color:'mdi-invert-colors',
  chat: 'mdi-chat-question-outline',
  file_undo:'mdi-file-undo-outline',
  undo: 'mdi-undo',
  save:'mdi-content-save',
  refresh: 'mdi-refresh',
  survey:'mdi-text-box-edit-outline',
  play:'mdi-play-circle',
  view_card:'mdi-view-grid-outline',
  view_table:'mdi-view-headline',
  chart:'mdi-chart-bar',
  chart_bubble:'mdi-chart-bubble',
  chart_word:'mdi-format-letter-starts-with',
  analysis:'mdi-google-analytics',
  share:'mdi-share-variant',
  qrcode:'mdi-qrcode',
  transcription: 'mdi-clipboard-text-play-outline',
  tag_outline: 'mdi-tag-outline',
  alarm: 'mdi-alarm',
  format_text: 'mdi-format-list-text',
  timeline: 'mdi-timeline-minus-outline',
  timeline_multi: 'mdi-timeline-text-outline',
  triangle_down:'mdi-triangle-small-down',
  triangle_up:'mdi-triangle-small-up',
  participant:'mdi-account-circle-outline',
  menu_swap:'mdi-menu-swap',
  reset_password:'mdi-lock-reset',
  add_link: 'mdi-link-variant-plus',
  usd: 'mdi-currency-usd',
  shield_account: 'mdi-shield-account',
  cart: 'mdi-cart',
  email_multiple: 'mdi-email-multiple',
  cancel: 'mdi-close-box',
  clear: 'mdi-broom',
  search_web: 'mdi-search-web',
  send: 'mdi-send',
  chevron_right: 'mdi-chevron-right',
  tune: 'mdi-tune',
  map_user:'mdi-map-marker-account-outline',
  arrow_right_drop_circle:'mdi-arrow-right-drop-circle',
  clock_outline: 'mdi-clock-outline',
  arrow_expand_all: 'mdi-arrow-expand-all',
  arrow_collapse_all: 'mdi-arrow-collapse-all',
  exclamation: 'mdi-exclamation',
  swap_vertica_outline:'mdi-swap-vertical-circle-outline',
  swap_horizontal_outline:'mdi-swap-horizontal-circle-outline',
  compare:'mdi-compare',
  form_select:'mdi-form-select',
  form_select2:'mdi-list-box-outline',
  arrow_down: 'mdi-arrow-down-drop-circle-outline',
  arrow_up: 'mdi-arrow-up-drop-circle-outline',
  trash: 'mdi-delete-variant',
  file_upload_outline: 'mdi-file-upload-outline',
  file_pdf:'mdi-file-pdf-box',
  file_excel:'mdi-microsoft-excel',
  file_word:'mdi-microsoft-word',
  file_pp:'mdi-microsoft-powerpoint',
  upload_cloud :'mdi-cloud-upload',
  expand: 'mdi-crop-square',
  collapse: 'mdi-code-block-brackets',
  media: 'mdi-archive',
  cube_scan: 'mdi-cube-scan',
  generate_video: 'mdi mdi-folder-play',
  eye_tracking: 'mdi mdi-eye-circle-outline',
  // #endregion Generals icons
  
  // #region Controls
  checkbox:'mdi-checkbox-blank-outline',
  checkbox_selected:'mdi-checkbox-marked',
  radio:'mdi-checkbox-blank-circle-outline',
  radio_selected:'mdi-checkbox-marked-circle',
  select: 'mdi-menu-open',
  select_selected: 'mdi-arrow-down-drop-circle',
  star:'mdi-star',
  folder_table_outline: 'mdi-folder-table-outline',
  // #endregion Controls

  // #region Sentiment
  positive:'mdi mdi-emoticon-happy-outline',
  mixed: 'mdi mdi-emoticon-confused-outline',
  neutral: 'mdi mdi-emoticon-neutral-outline',
  negative: 'mdi mdi-emoticon-sad-outline',
  // #endregion Sentiment

  // #region File
  video_file: 'mdi-file-video-outline',
  text_file: 'mdi-text-box-outline',
  // #endregion File

  // #region Arrows
  simple_arrow_up: 'mdi-chevron-up',
  simple_arrow_down: 'mdi-chevron-down',
  // #endregion Arrows
}