import { ConduxService } from '@/services/condux.service'
import {
  ConduxApiCommonV1Response,
  ConduxApiResearcherV1ResearcherInviteLinkPostBody,
  ConduxApiResearcherV1ResearcherInviteLinkPostResponse,
  ConduxApiResearcherV1ResearcherDecryptTokenPostBody,
  ConduxApiResearcherV1ResearcherDecryptTokenPostResponse,
  ConduxApiResearcherV1ResearcherSubscribePostBody,
  ConduxApiResearcherV1ResearcherCheckPostBody,
  ConduxApiResearcherV1ResearcherInviteEmailLinkPostBody,
  ConduxApiResearcherV1ResearcherInviteEmailLinkPostResponse,
  ConduxApiResearcherV1ResearcherHubspotRequest,
  ConduxApiResearcherV1ResearcherHubspotPostResponse,
} from '@conduxio/types'

export class RegisterService extends ConduxService {

  protected modelNameSingular = 'register'
  protected modelNamePlural = 'register'

  public generateLink(request: ConduxApiResearcherV1ResearcherInviteLinkPostBody): Promise<ConduxApiResearcherV1ResearcherInviteLinkPostResponse> {
    const apiRequest = {url: 'invite-link-post', workspaceId: '', object: request, modelName: 'link' }

    return super.post<ConduxApiResearcherV1ResearcherInviteLinkPostResponse>(apiRequest)
  }

  public sendInvite(request: ConduxApiResearcherV1ResearcherInviteEmailLinkPostBody): Promise<ConduxApiResearcherV1ResearcherInviteEmailLinkPostResponse> {
    const apiRequest = {url: 'invite-email-link', workspaceId: '', object: request, modelName: 'invite email link' }

    return super.post<ConduxApiResearcherV1ResearcherInviteEmailLinkPostResponse>(apiRequest)
  }

  public decryptToken(request: ConduxApiResearcherV1ResearcherDecryptTokenPostBody): Promise<ConduxApiResearcherV1ResearcherDecryptTokenPostResponse> {
    const apiRequest = {url: 'invite-decrypt', object: request, modelName: 'token decryption' }

    return super.post<ConduxApiResearcherV1ResearcherDecryptTokenPostResponse>(apiRequest)
  }

  public subscribe(request: ConduxApiResearcherV1ResearcherSubscribePostBody): Promise<ConduxApiCommonV1Response> {
    const apiRequest = {url: 'subscribe', object: request, modelName: 'subscription', withoutWorkspaceId:true }

    return super.post<ConduxApiCommonV1Response>(apiRequest)
  }

  public checkEmailRegistered(request: ConduxApiResearcherV1ResearcherCheckPostBody): Promise<ConduxApiCommonV1Response> {
    const apiRequest = {url: 'check', object: request, modelName: 'researcher check' }

    return super.post<ConduxApiCommonV1Response>(apiRequest)
  }

  public ownerSubscribe(request: ConduxApiResearcherV1ResearcherHubspotRequest): Promise<ConduxApiResearcherV1ResearcherHubspotPostResponse> {
    const apiRequest = {url: 'researcher/hubspot-registration', object: request, modelName: 'owner subscription' }

    return super.post<ConduxApiResearcherV1ResearcherHubspotPostResponse>(apiRequest)
  }
}
