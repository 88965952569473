import {
  ConduxApiCommonV1Response,
  ConduxApiCommonV1TokenPostResponse,
} from '@conduxio/types'
import axios, { AxiosResponse } from 'axios'
import { GlobalsService } from './globals.service'
import * as Sentry from '@sentry/vue';
import { TokenHelper } from '@/helpers/token.helper'

export class ConduxService {
  globalsService: GlobalsService
  protected urlPathPrefix = '/researcher/v1/'
  protected modelNameSingular: string; //e.g. study
  protected modelNamePlural: string; // e.g. studies
  public constructor(globalsService: GlobalsService) {
    this.modelNameSingular = ''
    this.modelNamePlural = ''
    this.globalsService = globalsService 
  }
  protected async get<T>(apiRequest: apiRequest): Promise<T> {
    if(!apiRequest.avoidTokenRefresh) 
      await this.checkTokenRefresh()   

    this.globalsService.incrementApiRequestInProgressCount()

    // Sets request url and params from the apiRequest
    const requestUrl = import.meta.env.VITE_APP_ROOT_API + this.urlPathPrefix + apiRequest.url
    const params: object = this.getQueryParameters(apiRequest)

    return axios.get(requestUrl, {
      headers: this.globalsService.userIdToken ? {
        'Content-Type': 'application/json',
        Authorization: this.globalsService.userIdToken,
      } : {
        'Content-Type': 'application/json',
      },
      params: params,
      paramsSerializer: {
        indexes: true, // use brackets with indexes
      }
    }).then((response: AxiosResponse<T>) => {
      return response.data
    },
    ).catch((error: any) => {
      let message = ''
      if(error && error.response && error.response.data && error.response.data.errorMessage)
      {
        message = error.response.data.errorMessage
      }
      else
      {
        message = apiRequest.modelName? 'Error retrieving ' + apiRequest.modelName : 'Error retrieving ' + this.modelNameSingular
      }
      return this.catchManager<T>(message, requestUrl, 'GET')
    }
    ).finally(() => {
      this.globalsService.decrementApiRequestInProgressCount()
    })
  }

  protected async post<T>(apiRequest: apiRequest): Promise<T> {
    if(!apiRequest.avoidTokenRefresh)
      await this.checkTokenRefresh()   

    this.globalsService.incrementApiRequestInProgressCount()

    // Sets request url and params from the apiRequest
    const requestUrl = import.meta.env.VITE_APP_ROOT_API + this.urlPathPrefix + apiRequest.url
    const params: object = this.getQueryParameters(apiRequest)
    
    return axios.post(requestUrl, apiRequest.object, {
      headers: this.globalsService.userIdToken ? {
        'Content-Type': 'application/json',
        Authorization: this.globalsService.userIdToken,
      } : {
        'Content-Type': 'application/json',
      },
      params: params 
    }).then((response: AxiosResponse<T>) => {    
      return response.data
    },
    ).catch((error: any) => {
      let message = ''
      if(error && error.response && error.response.data && error.response.data.errorMessage)
      {
        message = error.response.data.errorMessage
      }
      else
      {
        message = apiRequest.modelName? 'Error creating ' + apiRequest.modelName : 'Error creating ' + this.modelNameSingular
      }
      return this.catchManager<T>(message, import.meta.env.VITE_APP_ROOT_API + this.urlPathPrefix + this.modelNameSingular, 'POST')
    }
    ).finally(() => {
      this.globalsService.decrementApiRequestInProgressCount()
    })
  }

  protected async put<T>(apiRequest: apiRequest): Promise<T> {
    if(!apiRequest.avoidTokenRefresh) 
      await this.checkTokenRefresh()  

    if (!apiRequest.modelId) {
      return new Promise<T>((resolve) => {
        resolve({
          status: 'ERROR',
          errorMessage: 'Error updating ' + this.modelNameSingular + '. Error [' + this.modelNameSingular + ' id is missing or empty]',
        } as unknown as T)
      })
    }
    this.globalsService.incrementApiRequestInProgressCount()

    // Sets request url and params from the apiRequest
    const requestUrl = import.meta.env.VITE_APP_ROOT_API + this.urlPathPrefix + apiRequest.url
    const params: object = this.getQueryParameters(apiRequest)

    return axios.put(requestUrl, apiRequest.object, {
      headers: this.globalsService.userIdToken ? {
        'Content-Type': 'application/json',
        Authorization: this.globalsService.userIdToken,
      } : {
        'Content-Type': 'application/json',
      },
      params: params }
    ).then((response: AxiosResponse<T>) => {    
      return response.data
    },
    ).catch((error: any) => {
      let message = ''
      if(error && error.response && error.response.data && error.response.data.errorMessage)
      {
        message = error.response.data.errorMessage
      }
      else
      {
        message = apiRequest.modelName? 'Error updating ' + apiRequest.modelName : 'Error updating ' + this.modelNameSingular
      }
      return this.catchManager<T>(message, import.meta.env.VITE_APP_ROOT_API + this.urlPathPrefix + this.modelNameSingular, 'PUT')
    }
    ).finally(() => {
      this.globalsService.decrementApiRequestInProgressCount()
    })
  }

  protected async delete<T>(apiRequest: apiRequest): Promise<T> {
    if(!apiRequest.avoidTokenRefresh) 
      await this.checkTokenRefresh()
     
    if (!apiRequest.modelId) {
      return new Promise<T>((resolve) => {
        resolve({
          status: 'ERROR',
          errorMessage: 'Error deleting ' + this.modelNameSingular + '. Error [' + this.modelNameSingular + ' id is missing or empty]',
        } as unknown as T)
      })
    }
    this.globalsService.incrementApiRequestInProgressCount()

    // Sets request url and params from the apiRequest
    const requestUrl = import.meta.env.VITE_APP_ROOT_API + this.urlPathPrefix + apiRequest.url
    const params: object = this.getQueryParameters(apiRequest)

    return axios.delete(requestUrl, {
      headers: this.globalsService.userIdToken ? {
        'Content-Type': 'application/json',
        Authorization: this.globalsService.userIdToken,
      } : {
        'Content-Type': 'application/json',
      },
      params: params }
    ).then((response: AxiosResponse<T>) => {    
      return response.data
    },
    ).catch((error: any) => {
      let message = ''
      if(error && error.response && error.response.data && error.response.data.errorMessage)
      {
        message = error.response.data.errorMessage
      }
      else
      {
        message = apiRequest.modelName? 'Error deleting ' + apiRequest.modelName : 'Error deleting ' + this.modelNameSingular
      }
      return this.catchManager<T>(message, import.meta.env.VITE_APP_ROOT_API + this.urlPathPrefix + this.modelNameSingular, 'DELETE')
    }
    ).finally(() => {
      this.globalsService.decrementApiRequestInProgressCount()
    })
  }

  protected async getBackendInfo(): Promise<ConduxApiCommonV1Response> {
    this.globalsService.incrementApiRequestInProgressCount()

    return axios.get(import.meta.env.VITE_APP_ROOT_API + '/info/v1/version', {
      headers: {
        'Content-Type': 'application/json',
      } }
    ).then((response: AxiosResponse<ConduxApiCommonV1Response>) => {
      return response.data
    },
    ).catch((error: any) => {
      let message = ''
      if(error && error.response && error.response.data && error.response.data.errorMessage)
      {
        message = error.response.data.errorMessage
      }
      else
      {
        message = 'Error retrieving backend info version'
      }
      return this.catchManager<ConduxApiCommonV1Response>(message, import.meta.env.VITE_APP_ROOT_API + '/info/v1/version', 'GET')
    }
    ).finally(() => {
      this.globalsService.decrementApiRequestInProgressCount()
    })
  }

  protected UploadBigFile(file: any, url: string): Promise<ConduxApiCommonV1Response> {
    this.globalsService.incrementApiRequestInProgressCount()

    return axios.put(url, file, {
      headers: {
        'Content-Type': 'application/octet-stream',   // IMPORTANT: this works only with 'application/octet-stream'
      }
      ,
      // onUploadProgress: function (axiosProgressEvent) {
      //   /*{
      //     loaded: number;
      //     total?: number;
      //     progress?: number; // in range [0..1]
      //     bytes: number; // how many bytes have been transferred since the last trigger (delta)
      //     estimated?: number; // estimated time in seconds
      //     rate?: number; // upload speed in bytes
      //     upload: true; // upload sign
      //   }*/
      //   console.log('upload')
      //   console.log(axiosProgressEvent)
      // },
      // eslint-disable-next-line
      onUploadProgress: ({progress}) => {
        // console.log(((progress as number) * 100).toFixed(2));
      },
    }
    ).then((response: AxiosResponse<ConduxApiCommonV1Response>) => {
      const ret = {status : 'OK'} as ConduxApiCommonV1Response
      if (response.status !== 200)
      {
        ret.status = "ERROR"
        ret.errorMessage ='Error uploading big file'
      }
      return ret
    },
    ).catch((error: any) => {
      let message = ''
      if(error && error.response && error.response.data && error.response.data.errorMessage)
      {
        message = error.response.data.errorMessage
      }
      else
      {
        message = 'Error uploading big file'
      }
      return this.catchManager<ConduxApiCommonV1Response>(message, url , 'PUT')
    }
    ).finally(() => {
      this.globalsService.decrementApiRequestInProgressCount()
    })
  }

  public async translateText(sourceLang: string, targetLang: string, text: string): Promise<string> {
    this.globalsService.incrementApiRequestInProgressCount()

    return axios.post("https://swift-translate.p.rapidapi.com/translate", 
    { text: text, sourceLang: sourceLang, targetLang: targetLang}, {
      headers:  {
        'content-type': 'application/json',
        'X-RapidAPI-Key': '7b368f9a0emsh06e6ec871436e5ep1b2d55jsna8e5541ec9e6',
        'X-RapidAPI-Host': 'swift-translate.p.rapidapi.com'
      },
    }
    ).then((response: AxiosResponse<any>) => {
      return response.data.translatedText
    },
    ).catch((error: any) => {
      if(error && error.response && error.response.data && error.response.data.errorMessage)
      {
        return error.response.data.errorMessage
      }
      else
      {
        return 'Error on text translation'
      }
    }
    ).finally(() => {
      this.globalsService.decrementApiRequestInProgressCount()
    })
  }

  protected catchManager<T>(error: string, api: string, apiMethod: string): T
  {
    if (this.globalsService.isLoggedIn)
    {
      Sentry.setUser({ email: this.globalsService.loggedInUser.emailAddress })
    } 
    Sentry.setTag('platform', 'researcher')
    Sentry.setTag('api', api)
    Sentry.setTag('api-type', apiMethod)
    Sentry.captureException(error)
    return {
      status: 'ERROR',
      errorMessage: error,
    } as unknown as T
  }

  protected async checkTokenRefresh(): Promise<void>
  {
    if (this.globalsService.userIdToken)
    {
      if (!TokenHelper.isIdTokenValid(this.globalsService.userIdToken, this.globalsService.serverTimestampDifference)) {
        const apiRequest = {url: 'researcher/token', object: { refreshToken: this.globalsService.userRefreshToken as string }, modelName: 'token', avoidTokenRefresh: true, withoutWorkspaceId:true}
        await this.post<ConduxApiCommonV1TokenPostResponse>(apiRequest)
        .then((response) => {
          if (response.status === 'ERROR') {
            throw new Error(`Error refreshing user token. Error [${response.errorMessage}]`);
          } 
          else {
            this.globalsService.userIdToken = response.idToken
            this.globalsService.userRefreshToken = response.refreshToken
            const serverTimestampDifference = Date.now() - response.serverTimestamp
            this.globalsService.serverTimestampDifference = serverTimestampDifference
          }      
        })
      }
    }    
  }

  /* 
    Returns the a "params" object from the current apiRequest,
    with/without workspace_id query-string parameter
    how it works
    - a workspace_id query-string param can be mandatory or not
    - if the request has withoutWorkspaceId = true, the request doesn't require workspace_id
    - in any other case we DO need a workspace_id
    - if missing, the workspace_id has to be the same as globalService
  **/
  protected getQueryParameters(apiRequest: apiRequest): object {
    let params = {} as any
    if (apiRequest.request) {
      params = { ...apiRequest.request } 
    }
    if (!apiRequest.withoutWorkspaceId) {
      const workspaceId = apiRequest.workspaceId || this.globalsService.getWorkspaceId()
      if (workspaceId)
        params.workspace_id = workspaceId
    }
    if (apiRequest.include && apiRequest.include !== '') 
      params.include = apiRequest.include // appends the "include" param if given and not empty
   
    return params
  }
}

class apiRequest {
  public url = null as string | null
  public request? = null as any
  public withoutWorkspaceId? = false as boolean
  public workspaceId? = null as string | null
  public modelName? = null as string | null
  public object? = null as Object | null
  public modelId? = null as string | null
  public avoidTokenRefresh? = false
  public include? = null as string | null
}