import { ConduxService } from '@/services/condux.service'
import {
  ConduxApiCommonV1Response,
  ConduxApiResearcherV1SessionGetResponse,
  ConduxApiResearcherV1SessionPostResponse,
  ConduxApiResearcherV1SessionPutResponse,
  ConduxApiResearcherV1SessionsGetRequest,
  ConduxApiResearcherV1SessionsGetResponse,
  ConduxApiResearcherV1SessionPostBody,
  ConduxApiResearcherV1GeneratePlaybackDownloadPostRequest,
  Session,
  SessionHelper,
} from '@conduxio/types'

export class SessionService extends ConduxService {

  protected modelNameSingular = 'session'
  protected modelNamePlural = 'sessions'

  public read(id: string, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1SessionGetResponse> {
    const apiRequest = {url: this.modelNameSingular + '/' + id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId }

    return super.get<ConduxApiResearcherV1SessionGetResponse>(apiRequest)
  }

  public readAll(request: ConduxApiResearcherV1SessionsGetRequest, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1SessionsGetResponse> {
    const apiRequest = {url: this.modelNameSingular, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, request: request }

    return super.get<ConduxApiResearcherV1SessionsGetResponse>(apiRequest)
  }
  
  public create(request: ConduxApiResearcherV1SessionPostBody, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1SessionPostResponse> {
    const validationResult = SessionHelper.validateSessionPost(request.session)

    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1SessionPostResponse>((resolve) => {
        resolve({
          status: 'ERROR',
          errorMessage: 'Error creating Session, ' + validationResult.invalidReason,
        } as ConduxApiResearcherV1SessionPostResponse)
      })
    }
    const apiRequest = {url: this.modelNameSingular, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, object: request }

    return super.post<ConduxApiResearcherV1SessionPostResponse>(apiRequest)
  }

  public update(model: Session, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1SessionPutResponse> {
  /*const validationResult = SessionHelper.validateSessionPut(model)

    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1SessionPutResponse>((resolve) => {
        resolve({
          status: 'ERROR',
          errorMessage: 'Error updating Session, ' + validationResult.invalidReason,
        } as ConduxApiResearcherV1SessionPutResponse)
      })
    } */

    const apiRequest = {url: this.modelNameSingular + '/' + model.id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, object: { session: model }, modelId: model.id }

    return super.put<ConduxApiResearcherV1SessionPutResponse>(apiRequest)
  }

  public remove(id: string, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiCommonV1Response> {
    const apiRequest = {url: this.modelNameSingular + '/' + id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, modelId: id }

    return super.delete<ConduxApiCommonV1Response>(apiRequest)
  }

  public generatePlaybackDownload(request: ConduxApiResearcherV1GeneratePlaybackDownloadPostRequest, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiCommonV1Response> {  
    const apiRequest = {url: 'session-playback-download', withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, object: request }

    return super.post<ConduxApiCommonV1Response>(apiRequest)
  }
}
